import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { NgxMaskModule } from "ngx-mask";
import { NgBusyModule } from "ng-busy";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { MoneyFormatPipe } from "../core/pipes/money-format.pipe";

// import { PaginationModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SearchPatientComponent } from "./components/search-patient/search-patient.component";
import { FormClientComponent } from "../bureau-des-entrees/main-accueil-reception/form-client/form-client.component";
import { MainListeAccueilByEtapeComponent } from "./components/main-liste-accueil-by-etape/main-liste-accueil-by-etape.component";
import { AddDocumentComponent } from "./components/add-document/add-document.component";
import { SearchMedicamentComponent } from "./components/search-medicament/search-medicament.component";
import { CKEditorModule } from "ng2-ckeditor";
import { MatTabsModule } from "@angular/material/tabs";
import { FindMedocComponent } from "./components/find-medoc/find-medoc.component";
import { InfoPatientConsultationComponent } from "../unite-consultation/info-patient-consultation/info-patient-consultation.component";
import { DetailsDocumentsJointsComponent } from "./components/details-documents-joints/details-documents-joints.component";
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from "@ng-select/ng-select";
import { FormPreviewPdfComponent } from "./components/form-preview-pdf/form-preview-pdf.component";
import { MainDetailsPlainteComponent } from "../reclamation/main-details-plainte/main-details-plainte.component";
import { HistoriqueCircuitTraitementComponent } from "./components/historique-circuit-traitement/historique-circuit-traitement.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FormPrevPdfHistoResultatComponent } from "./components/form-prev-pdf-histo-resultat/form-prev-pdf-histo-resultat.component";
import { AddDocumentRadioComponent } from "./components/add-document-radio/add-document-radio.component";
import { FormReceptionPatientDialyseComponent } from "../bureau-des-entrees/main-accueil-reception/form-reception-patient-dialyse/form-reception-patient-dialyse.component";
import { AddDocumentFicheEngagementComponent } from "./components/add-document-fiche-engagement/add-document-fiche-engagement.component";
import { DetailVisiteMedecinComponent } from "./components/detail-visite-medecin/detail-visite-medecin.component";
import { HistoriqueDemandesServicesComponent } from "../gestion-consomation/produits-unit-fonct/historique-demandes-services/historique-demandes-services.component";
import { FormProduitsUnitFonctComponent } from "../gestion-consomation/produits-unit-fonct/form-produits-unit-fonct/form-produits-unit-fonct.component";
import { FormEnregistrerLivraisonComponent } from "../gestion-consomation/produits-unit-fonct/form-enregistrer-livraison/form-enregistrer-livraison.component";
import { SearchMedocByUniteFonctionnelleComponent } from "./components/search-medoc-by-unite-fonctionnelle/search-medoc-by-unite-fonctionnelle.component";
import { HistoriqueLivraisonAuxServicesComponent } from "../pharmacie/livraison-produit-aux-services/historique-livraison-aux-services/historique-livraison-aux-services.component";
import { ElementByPagePaginatorComponent } from "./components/element-by-page-paginator/element-by-page-paginator.component";
import { TimePickerComponent } from "./components/time-picker/time-picker.component";
import { MainListeAccueilDialyseByEtapeComponent } from "./components/main-liste-accueil-dialyse-by-etape/main-liste-accueil-dialyse-by-etape.component";
import { ListeRequetesDialyseComponent } from "../unite-consultation/main-dialyse/main-prescription-hebdo/liste-requetes-dialyse/liste-requetes-dialyse.component";
import { MainRequetesDialyseComponent } from "../unite-consultation/main-dialyse/main-requetes-dialyse/main-requetes-dialyse.component";
import { SeanceDialyseComponent } from "../bureau-des-entrees/main-accueil-dialyse/seance-dialyse/seance-dialyse.component";
import { HistoriqueAccueilsDialyseComponent } from "../bureau-des-entrees/main-accueil-dialyse/seance-dialyse/historique-accueils-dialyse/historique-accueils-dialyse.component";
import { HistoriqueSeanceDialyseComponent } from "../bureau-des-entrees/main-accueil-dialyse/seance-dialyse/historique-seance-dialyse/historique-seance-dialyse.component";
import { DetailsPatientsProgrammeComponent } from "../bureau-des-entrees/main-accueil-dialyse/programme-dialyse/programme-dialyse-agenda/details-patients-programme/details-patients-programme.component";
import { FormCreateProgrammeComponent } from "../bureau-des-entrees/main-accueil-dialyse/programme-dialyse/form-create-programme/form-create-programme.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PosologiePrescriptionComponent } from "./components/posologie-prescription/posologie-prescription.component";
import { TimeLineComponent } from "./components/time-line/time-line.component";
import { FormCertificatMedicalComponent } from "./components/form-certificat-medical/form-certificat-medical.component";
import { PriseDeDecisionWorkflowComponent } from "./components/prise-de-decision-workflow/prise-de-decision-workflow.component";
import { AppAccessDirective } from "./directive/app-access.directive";
import { HistoriqueRetourComponent } from "../pharmacie/historique-retour/historique-retour.component";
// import { CKEditorModule } from 'ng2-ckeditor';
import { ChartModule } from 'angular-highcharts';
import { FormPrevDecompteHospiComponent } from "./components/form-prev-decompte-hospi/form-prev-decompte-hospi.component";
import { SearchMdicamentProComponent } from "./components/search-mdicament-pro/search-mdicament-pro.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ChartsModule as chartjsModule } from "ng2-charts";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgApexchartsModule } from "ng-apexcharts";
import { SearchMedicamentRuptureStockComponent } from "./components/search-medicament-rupture-stock/search-medicament-rupture-stock.component";
import { SearchMedicamentInMagasinComponent } from "./components/search-medicament-in-magasin/search-medicament-in-magasin.component";
import { HighchartsChartModule } from 'highcharts-angular';
import { LockScreenModalComponent } from "../core/service/lock-screen-modal/lock-screen-modal.component";
import { FormDemandeHospitalisationComponent } from "../unite-consultation/main-praticien/form-praticien/form-demande-hospitalisation/form-demande-hospitalisation.component";
import { FormPriseConstantVmComponent } from "./components/detail-visite-medecin/form-prise-constant-vm/form-prise-constant-vm.component";
import { AgeRangeMaskDirective } from './directive/age-range-mask.directive';
import { FormEnvoyerCaisseComponent } from "../unite-consultation/main-praticien/form-praticien/form-envoyer-caisse/form-envoyer-caisse.component";
import { AutoResizeDirective } from './directive/auto-resize.directive';
import { SearchPatientNewComponent } from "./components/search-patient-new/search-patient-new.component";
import { SearchPatientEnqueteComponent } from "./components/search-patient-enquete/search-patient-enquete.component";
import { FormRequeteDialyseComponent } from "../unite-consultation/main-praticien/form-praticien/form-requete-dialyse/form-requete-dialyse.component";
@NgModule({
  declarations: [
    MoneyFormatPipe,
    SearchPatientComponent,
    FormClientComponent,
    MainListeAccueilByEtapeComponent,  
    AddDocumentComponent,
    SearchMedicamentComponent,
    InfoPatientConsultationComponent,
    FindMedocComponent,
    DetailsDocumentsJointsComponent,
    FormPreviewPdfComponent,
    FormPrevPdfHistoResultatComponent,
    MainDetailsPlainteComponent,
    HistoriqueCircuitTraitementComponent,
    AddDocumentRadioComponent,
    FormReceptionPatientDialyseComponent,
    AddDocumentFicheEngagementComponent,
    DetailVisiteMedecinComponent,
    HistoriqueDemandesServicesComponent,
    FormProduitsUnitFonctComponent,
    FormEnregistrerLivraisonComponent,
    SearchMedocByUniteFonctionnelleComponent,
    HistoriqueLivraisonAuxServicesComponent,
    ElementByPagePaginatorComponent,
    TimePickerComponent,
    MainListeAccueilDialyseByEtapeComponent,
    ListeRequetesDialyseComponent,
    MainRequetesDialyseComponent,
    SeanceDialyseComponent,
    HistoriqueAccueilsDialyseComponent,
    HistoriqueSeanceDialyseComponent,
    PosologiePrescriptionComponent,
    FormCreateProgrammeComponent,
    TimeLineComponent,
    FormCertificatMedicalComponent,
    PriseDeDecisionWorkflowComponent,
    HistoriqueRetourComponent,
    AppAccessDirective,
    FormPrevDecompteHospiComponent,
    SearchMdicamentProComponent,
    SearchMedicamentRuptureStockComponent,
    SearchMedicamentInMagasinComponent,
    LockScreenModalComponent,
    FormDemandeHospitalisationComponent,
    FormPriseConstantVmComponent,
    AgeRangeMaskDirective,
    FormEnvoyerCaisseComponent,
    AutoResizeDirective,
    SearchPatientNewComponent,
    SearchPatientEnqueteComponent,
    FormRequeteDialyseComponent
    // ProgrammeDialyseComponent,
    // FormCreateProgrammeComponent,
    // ProgrammeDialyseAgendaComponent,
    // HistoriqueProgrammeDialyseComponent,
    // DetailsPatientsProgrammeComponent
    
  ],
  imports: [
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    NgBusyModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatTabsModule,
    NgSelectModule,
    FullCalendarModule, 
    MatIconModule,
    MatButtonModule,
    CKEditorModule,
    ChartModule,
    PopoverModule.forRoot(),
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'red',
      switchColor: 'white',
      defaultBgColor: 'green',
      defaultBoColor : '#476EFF',
      checkedLabel: 'Inactif',
      uncheckedLabel: 'Actif',
      checkedTextColor:'white',
      uncheckedTextColor:'white'
    }),
    chartjsModule,
    NgApexchartsModule,
    PerfectScrollbarModule, 
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    HighchartsChartModule
    
  ],
  exports: [
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    NgBusyModule,
    PaginationModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    PopoverModule,
    // PaginationModule.forRoot(),
    // NgxMaskModule,
    MoneyFormatPipe,
    BsDatepickerModule,
    SearchPatientComponent,
    FormClientComponent,
    MainListeAccueilByEtapeComponent,
    AddDocumentComponent,
    SearchMedicamentComponent,
    FindMedocComponent,
    InfoPatientConsultationComponent,
    DetailsDocumentsJointsComponent,
    FormPreviewPdfComponent,
    MainDetailsPlainteComponent,
    UiSwitchModule,
    NgSelectModule,
    FullCalendarModule,

    NgbModule,
    HistoriqueCircuitTraitementComponent,
    FormPrevPdfHistoResultatComponent,
    AddDocumentRadioComponent,
    FormReceptionPatientDialyseComponent,
    AddDocumentFicheEngagementComponent,
    DetailVisiteMedecinComponent,
    HistoriqueDemandesServicesComponent,
    FormProduitsUnitFonctComponent,
    FormEnregistrerLivraisonComponent,
    SearchMedocByUniteFonctionnelleComponent,
    HistoriqueLivraisonAuxServicesComponent,
    ElementByPagePaginatorComponent,
    TimePickerComponent ,
    MainListeAccueilDialyseByEtapeComponent,
    ListeRequetesDialyseComponent,
    MainRequetesDialyseComponent,
    SeanceDialyseComponent,
    HistoriqueAccueilsDialyseComponent,
    HistoriqueSeanceDialyseComponent,
    PosologiePrescriptionComponent,
    FormCreateProgrammeComponent,
    TimeLineComponent,
    FormCertificatMedicalComponent,
    PriseDeDecisionWorkflowComponent,
    HistoriqueRetourComponent,
    AppAccessDirective,
    FormPrevDecompteHospiComponent,
    ChartModule,
    SearchMdicamentProComponent,
    chartjsModule,
    NgApexchartsModule,
    PerfectScrollbarModule, 
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    NgApexchartsModule,
    PerfectScrollbarModule, 
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    SearchMedicamentRuptureStockComponent,
    SearchMedicamentInMagasinComponent,
    HighchartsChartModule,
    FormDemandeHospitalisationComponent,
    FormPriseConstantVmComponent,
    AgeRangeMaskDirective,
    FormEnvoyerCaisseComponent,
    AutoResizeDirective,
    SearchPatientNewComponent,
    SearchPatientEnqueteComponent,
    FormRequeteDialyseComponent

  ],
  entryComponents:[TimePickerComponent]
})
export class SharedModule {}
