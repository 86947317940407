<ng-container>
    <form class="m-1">

        <div class="row">

            <div class="col-lg-12 col-xs-12 col-sm-12"  [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
                <ng-container>
                    <div class="details-info-part">

                        <fieldset class="custom-fieldset">
                            <legend> Infos patient </legend>

                            <div class="row">
                                <div class="col-md-11">
                                    <div class="row">

                                        <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 mb-3">
                                            <label>N°Accueil :</label>
                                            <input class="form-control custom-input" placeholder="Accueil"
                                                autocomplete="off" [(ngModel)]="itemToSave.code" [disabled]="true"
                                                name="numRecuTicket"
                                                required type="text">
                                        </div> -->
                                        <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 mb-3">
                                            <label>Date et Heure :</label>
                                            <input class="form-control custom-input" placeholder="Date et heure"
                                                autocomplete="off" [(ngModel)]="itemToSave.createdAt" [disabled]="true"
                                                name="numRecuTic" required
                                                type="text">
                                        </div> -->

                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 mb-3">
                                            <label>Unité fonctionnelle : </label>
                                            <input class="form-control custom-input" placeholder="Unité fonctionnelle"
                                                autocomplete="off" [(ngModel)]="itemToSave.uniteFonctionnelle" [disabled]="true"
                                                name="uniteFonctionnelle"
                                                required type="text">
                                        </div>
                                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-3">
                                            <label>Médecin :
                                                <span class="required_champs">*</span>
                                            </label>
                                            <input disabled class="form-control custom-input" placeholder="Nom & Prénoms"
                                                autocomplete="off" [(ngModel)]="itemToSave.infirmier" [disabled]="true"
                                                name="infirmier" required
                                                type="text">
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-3">
                                            <label>Patient : </label>
                                            <input class="form-control custom-input" placeholder="Nom & Prénoms"
                                                autocomplete="off" [(ngModel)]="itemToSave.patient" [disabled]="true"
                                                name="nomPrenom" required
                                                type="text">
                                        </div>

                                        <div class="col-xl-2 col-lg-6 col-md-6 col-sm-3 mb-3">
                                            <label>Matricule : </label>
                                            <input class="form-control custom-input" placeholder="Matricule"
                                                autocomplete="off" [(ngModel)]="itemToSave.matriculeBeneficiaire" [disabled]="true"
                                                 name="matriculeBeneficiaire" required
                                                type="text">
                                        </div>

                                        

                                        <div class="col-xl-1 col-lg-3 col-md-3 col-sm-3 mb-3">
                                            <label>Genre : </label>
                                            <input class="form-control custom-input" placeholder="Sexe"
                                                autocomplete="off" [(ngModel)]="itemToSave.sexePatient" [disabled]="true"
                                               name="sexePatient" required
                                                type="text">
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-3">
                                            <label>Age : </label>
                                            <input class="form-control custom-input" placeholder="Age"
                                                autocomplete="off" [(ngModel)]="patientAge" [disabled]="true"
                                                name="patientAge" required
                                                type="text">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-1">
                                    <!-- <img src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                                        alt="Photo assuré" class="pull-right img-assure" /> -->
                                        <img
                                        [src]="imgUrl" (error)="handleError()"
                                        alt="Photo assuré"
                                        class="pull-right img-assure"
                                        style="width: 150px;height: 150px;"
                                        />
                                </div>

                            </div>

                        </fieldset>

                    </div>

                    <div class="form-part">

                        <fieldset class="custom-fieldset">
                            <legend> Prise de constante </legend>

                            <div class="row">

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Température (°C) :<span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom-input" placeholder="Température"
                                        autocomplete="off" [(ngModel)]="itemToSave.temperature" name="temperature" required
                                        type="text" mask="separator">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>TAG (mmHg) :<span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom-input" placeholder="TAG" autocomplete="off"
                                        [(ngModel)]="itemToSave.tag" name="tag" required type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>TAD (mmHg):
                                    </label>
                                    <input class="form-control custom-input" placeholder="TAD" autocomplete="off"
                                        [(ngModel)]="itemToSave.tad" name="tad" required type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Poids (Kg) :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom-input" placeholder="Poids" autocomplete="off"
                                        [(ngModel)]="itemToSave.poids" name="poids" mask="separator"  (keyup)="getImcAndImg()" type="text">
                                </div>

                                <div class="col-xl-1 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Taille (Cm) :
                                    </label>
                                    <input class="form-control custom-input" placeholder="Taille" autocomplete="off"
                                        [(ngModel)]="itemToSave.taille" mask="separator" name="taille"  (keyup)="getImcAndImg()"
                                        type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>IMC (Kg / m<sup>2</sup>):
                                    </label>
                                    <input class="form-control custom-input" placeholder="IMC" autocomplete="off"
                                        [(ngModel)]="itemToSave.imc" name="imc" disabled type="text">
                                </div>

                                <div class="col-xl-1 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>IMG (%):
                                    </label>
                                    <input class="form-control custom-input" placeholder="IMG" autocomplete="off"
                                        [(ngModel)]="itemToSave.img"  name="img" disabled
                                        type="text">
                                </div>

                            </div>


                            <div class="row">

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Pull (B/Min) :
                                    </label>
                                    <input class="form-control custom-input" placeholder="Pull" autocomplete="off"
                                        [(ngModel)]="itemToSave.pulls" name="pulls" required type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Fréq respiratoire (C / Min) :
                                    </label>
                                    <input class="form-control custom-input" placeholder="Fréq respiratoire"
                                        autocomplete="off" [(ngModel)]="itemToSave.frequenceRespiratoire" name="frequenceRespiratoire" required
                                        type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Périmètre Cranien (cm):
                                    </label>
                                    <input class="form-control custom-input" placeholder="Périmètre Cranien"
                                        autocomplete="off" [(ngModel)]="itemToSave.pc" name="pc" mask="separator"
                                        type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Périmètre Ombilical (cm) :
                                    </label>
                                    <input class="form-control custom-input" placeholder="Périmètre Ombilical (cm)"
                                        autocomplete="off" [(ngModel)]="itemToSave.po" name="po" mask="separator"
                                        type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Périmètre Brachial (cm) :
                                    </label>
                                    <input class="form-control custom-input" placeholder="Périmètre Brachial (cm)"
                                        autocomplete="off" [(ngModel)]="itemToSave.pb" mask="separator"
                                        name="pb" required type="text">
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Zscore:
                                    </label>
                                    <input class="form-control custom-input" placeholder="Zscore" autocomplete="off"
                                        [(ngModel)]="itemToSave.zscore" name="zscore" required type="text">
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Albumine:
                                    </label>
                                    <input class="form-control custom-input" placeholder="Albumine" autocomplete="off"
                                        [(ngModel)]="itemToSave.albumine" name="albumine" required type="text">
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3">
                                    <label>Sucre:
                                    </label>
                                    <input class="form-control custom-input" placeholder="Sucre" autocomplete="off"
                                        [(ngModel)]="itemToSave.sucre" name="sucre" required type="text">
                                </div>

                            </div>

                        </fieldset>



                    </div>
                </ng-container>
            </div>


        </div>

        <div class="row btn-manger-part">

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                <button type="button" class="btn btn-md custom_btn_primary pull-right"
                    (click)="confirmSaveItem(itemToSave)">   {{priseConstanteToUpdate?.id ? 'Modifier' : 'Enregistrer'}}  </button>

                <button type="button" class="btn btn-md btn-cancel pull-right"
                    (click)="fermertureFormulaire()">Fermer</button>
            </div>

        </div>

    </form>

</ng-container>