<ng-container>
    <div class="row" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
        <div class="col-12 mb-3" *ngIf="itemToSave?.createdAt && itemToSave?.enregistrerPar">
            <b class="pull-right derniere-info"> Dernière modification le {{itemToSave?.createdAt}} par
                {{itemToSave?.enregistrerPar}} </b>
        </div>

        <div class="col-12 mb-3" *ngIf="!isNewSearchPatient">
            <div class="row">
                <div class="col-2 mb-2">
                    <label>N° requête:
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="N° ordonnance"
                        autocomplete="off" [(ngModel)]="itemOldOrdonance.numeroOrdonnance" name="numeroOrdonnance"
                        [disabled]="true" required type="text" style="font-size: 9px !important;font-weight: bold;">
                </div>

                <div class="col-2 mb-2">
                    <label>Date et Heure Cons:
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="Date et Heure Cons"
                        autocomplete="off" [(ngModel)]="consultation.createdAt" name="createdAt" [disabled]="true"
                        required type="text">
                </div>

                <div class="col-2 mb-2">
                    <label>N° Consultation :
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="N°Consultation"
                        autocomplete="off" [(ngModel)]="consultation.numeroConsultation" name="numeroConsultation"
                        [disabled]="true" required type="text">
                </div>

                <div class="col-3 mb-2">
                    <label>Medecin traitant :
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="Medecin traitant"
                        autocomplete="off" [(ngModel)]="currentPatient.medecin" name="medecin" [disabled]="true"
                        required type="text">
                </div>

                <div class="col-3 mb-2">
                    <label>Unité fonctionnelle :
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="Unité fonctionnelle"
                        autocomplete="off" [(ngModel)]="currentPatient.adminUniteFonctionnelleLibelle"
                        name="adminUniteFonctionnelleLibelle" [disabled]="true" required type="text">
                </div>
            </div>
        </div>

        <fieldset class="custom-fieldset mb-2" *ngIf="!isNewSearchPatient">
            <legend> Info patient </legend>

            <div class="col-12">
                <app-info-patient-consultation [currentPatient]="currentPatient" [withAssurances]="true" [hassNeedGroupSanguin]="true">
                </app-info-patient-consultation>
            </div>
        </fieldset>

        <div class="col-12 mb-3" *ngIf="isNewSearchPatient">
            <fieldset class="custom-fieldset mb-2" >
                <legend> Info patient </legend>
    
                <div class="col-12">

                    <div class="col-12 mb-3">
                        <div class="col-md-3 col-sm-12 mb-0">
                            <label>Catégorie patient :
                                <span class="required_champs">*</span>
                            </label>
                           <select class="form-control form-select" name="categorie" [(ngModel)]="itemToSearch.typeAssure">
                             <option value="">-- Choisissez --</option>
                             <option value="ASSURE">Assuré</option>
                             <option value="NON_ASSURE">Non Assuré</option>
                           </select>
                        </div>
                    </div>
            
                     <div class="col-12 mb-3 mt-3" *ngIf="(itemToSearch.typeAssure && itemToSearch.typeAssure.toLowerCase() == 'assure')">
                        <app-search-patient (itemPatientSelected)="selectedPatient($event)"
                         [beneficiaireId]="beneficiaireId"  [isSearchBysearchString]="true">
                        </app-search-patient>
                     </div>

                       <!-- Au cas ou il est non assuré -->
                <ng-container *ngIf="itemToSearch.typeAssure && itemToSearch.typeAssure.toLowerCase() == 'non_assure' ">
                    <div class=" mb-3 mt-3 search-patient-part">
                        <app-search-patient (itemPatientSelected)="selectedPatient($event)" [beneficiaireId]="beneficiaireId"  [isSearchBysearchString]="true">
                        </app-search-patient>
                    </div>
                </ng-container>

                    <!-- <app-search-patient [withAssurances]="true" [hassNeedGroupSanguin]="true">
                    </app-search-patient> -->
                </div>
            </fieldset>
        </div>

        <fieldset class="custom-fieldset ">
            <legend> Info requête</legend>

            <div class="row">

                <div class="col-md-8">

                    <div class="row">
                        <div class="col-md-4 mb-2 col-sm-12">
                            <label>Type de dialyse <span class="required_champs">*</span>:
                            </label>

                            <ng-select [(ngModel)]="itemToSave.typeDialyseSelected" placeholder="Choisissez"
                                name="typeDialyseSelected" id="typeDialyseSelected" [multiple]="false"
                                [closeOnSelect]="true">
                                <ng-option *ngFor="let item of listTypeDialyse" [value]="item">
                                    {{ item?.libelle }}
                                </ng-option>
                            </ng-select>

                        </div>

                        <div class="col-md-4 mb-2 col-sm-12">
                            <label>Jour de dialyse <span class="required_champs">*</span>:
                            </label>

                            <ng-select [(ngModel)]="itemToSave.jourDialyseSelected" placeholder="Choisissez"
                                name="jourDialyseSelected" id="jourDialyseSelected" [multiple]="false"
                                [closeOnSelect]="true">
                                <ng-option *ngFor="let item of listJourDialyse" [value]="item">
                                    {{ item?.libelle }}
                                </ng-option>
                            </ng-select>

                        </div>

                        <div class="col-md-4 mb-2 col-sm-12">
                            <label>Période dialyse <span class="required_champs">*</span>:
                            </label>

                            <ng-select [(ngModel)]="itemToSave.periodeDialyseSelected" placeholder="Choisissez"
                                name="periodeDialyseSelected" id="periodeDialyseSelected" [multiple]="false"
                                [closeOnSelect]="true">
                                <ng-option *ngFor="let item of listPeriodeDialyse" [value]="item">
                                    {{ item?.libelle }}
                                </ng-option>
                            </ng-select>

                        </div>


                    </div>

                   
                    <!-- <div class="row">
                      
                        <div class="col-12 mb-2" style="font-weight: bold;">
                            Est-ce une urgence ? <input [(ngModel)]="itemToSave.isUrgence" name="isUrgence"
                                type="checkbox">
                        </div>
                    </div> -->

                    <div class="row">
                        <!-- <div class="col-6 mb-1">
                            <label>Nombre de séance :
                            </label>
                            <input class="form-control custom-input" placeholder="Nombre séance" autocomplete="off"
                                [(ngModel)]="itemToSave.nbreSeance" name="nbreSeance" mask="separator"  [disabled]="isHistoConsultation" 
                                type="text">
                        </div> -->

                        <div class="col-md-3 col-sm-12 mb-1">
                            <label>Abord vasculaire : </label>

                            <div class="row">
                                <div class="col-12 mb-1"> 
                                    Pose catheter ? &nbsp;<input [(ngModel)]="itemToSave.isCatheter" name="isCatheter"
                                    type="checkbox">
                                </div>

                                <div class="col-12 mb-1">
                                    Création fav ? &nbsp;<input [(ngModel)]="itemToSave.isCreationFav" name="isCreationFav"
                                        type="checkbox">
                                </div>
                            </div>

                            <!-- <ng-select [(ngModel)]="itemToSave.abordVasculaireSelected" placeholder="Choisissez"
                                name="abordVasculaireSelected" id="abordVasculaireSelected" [multiple]="false"
                                [closeOnSelect]="true" [disabled]="isHistoConsultation">
                                <ng-option *ngFor="let item of listAbordVasculaire" [value]="item">
                                    {{ item?.libelle }}
                                </ng-option>
                            </ng-select> -->

                        </div>

                        <div class="col-md-3 col-sm-12 mb-2">
                            <label>Dialyse : </label>
                            <div class="row">
                                <div class="col-12 mb-1"> 
                                    Ittérative ? &nbsp;<input [(ngModel)]="itemToSave.isIterative" name="isIterative"
                                    type="checkbox">
                                </div>

                                <div class="col-12 mb-1">
                                   Une urgence ? &nbsp;<input [(ngModel)]="itemToSave.isUrgence" name="isUrgence"
                                        type="checkbox">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-12 mb-2">
                            <label>Nbre de séance <span class="required_champs">*</span>:
                            </label>
                            <input class="form-control custom-input" placeholder="Nombre séance" autocomplete="off"
                                [(ngModel)]="itemToSave.nbreSeance" name="nbreSeance" mask="separator"
                                [disabled]="isHistoConsultation" type="text">
                        </div>

                        <div class="col-md-3 col-sm-12 mb-1">
                            <label>Télephone <span class="required_champs">*</span>:
                            </label>
                            <input class="form-control custom-input" placeholder="Télephone" autocomplete="off"
                                [(ngModel)]="itemToSave.contact" name="contact" mask="00 00 00 00 00"
                                [disabled]="isHistoConsultation" type="text">
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-12 mb-1">
                            <label>Observation :</label>
                            <textarea class="form-control" rows="4" name="observation" placeholder="Observation" [(ngModel)]="itemToSave.observation"></textarea>
                        </div>
                    </div>

                  
                </div>

                <div class="col-md-4 col-sm-12">

                    <div class="row">
                        <div class="col-md-7 col-sm-12 mb-2">
                            <label>Medecin reférent :
                            </label>
                            <input class="form-control custom-input" placeholder="Médecin reférent" autocomplete="off"
                                [(ngModel)]="itemToSave.medecinReferent" name="medecinReferent"
                                [disabled]="isHistoConsultation" type="text">
                        </div>

                        <div class="col-md-5 col-sm-12 mb-2 checkbox-div">
                            Patient Diabétique ? &nbsp;<input [(ngModel)]="itemToSave.isDiabete" name="isDiabetique" type="checkbox"
                                [disabled]="isHistoConsultation">
                        </div>
                    </div>

                    <div class="row">
                        <!-- <div class="col-md-4 col-sm-12 mb-2">
                            <label>Date de 1er EER :
                            </label>

                            <input type="text" placeholder="Date de 1er" class="form-control" name="dateFirstEer"
                                [(ngModel)]="itemToSave.dateFirstEer" bsDatepicker [maxDate]="dateActuelle"
                                [disabled]="isHistoConsultation" autocomplete="off" placement="top"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' }">

                        </div> -->

                        <div class="col-md-12 col-sm-12">
                            <label>Etablissement provenance: </label>
                            <ng-select [(ngModel)]="itemToSave.etablissementProvenanceSelected" placeholder="Choisissez"
                                name="etablissementProvenanceSelected" id="etablissementProvenanceSelected"
                                [multiple]="false" [closeOnSelect]="true" [disabled]="isHistoConsultation">
                                <ng-option *ngFor="let item of listEtablissementProvenance" [value]="item">
                                    {{ item?.raisonSociale }}
                                </ng-option>
                            </ng-select>
                        </div>

                        <!-- <div class="col-md-3 col-sm-12 mb-2">
                            <label>Télephone <span class="required_champs">*</span>:</label>
                            <input class="form-control custom-input" placeholder="Télephone" autocomplete="off"
                                [(ngModel)]="itemToSave.nbreSeance" name="nbreSeance" mask="separator"
                                [disabled]="isHistoConsultation" type="text">
                        </div> -->

                    </div>

                    <div class="row" *ngIf="isNewSearchPatient">
                        <div class="col-12 mt-3" [class.overflowY]="listPatientEnReqete && listPatientEnReqete.length > 5">
                            <table class="table table-condensed table-striped">
                                <thead>
                                    <th>#</th>
                                    <th>Matricule</th>
                                    <th>Nom & Prénoms</th>
                                    <th>Date de naissance</th>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of listPatientEnReqete; let i = index" (click)="selectedPatientEnDialyse(item)" style="cursor: pointer;">
                                        <td>{{i+1}}</td>
                                        <td>{{item?.matriculeBeneficiaire}}</td>
                                        <td>{{item?.nom+" "+item?.prenom}}</td>
                                        <td>{{item?.dateNais}}</td>
                                    </tr>

                                    <tr *ngIf="!listPatientEnReqete || listPatientEnReqete.length == 0">
                                        <td colspan="4" class="txt-align-center"> Aucune données disponibles </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </fieldset>


        <div class="row" *ngIf="!isHistoConsultation">
            <ng-container>
                <div class="btn-manger-part">

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding: 0;">

                        <!-- <button type="button" class="btn btn-md custom_btn_primary pull-right"
                            (click)="confirmSaveItem(itemToSave)" [class.auth-spinner]="loading" [disabled]="isHistoConsultation">
                            Enregistrer </button> -->
                        <button type="button" class="btn btn-md custom_btn_primary pull-right"
                            (click)="confirmSaveItem(itemToSave)" [class.auth-spinner]="loading"
                            [disabled]="isHistoConsultation">
                           {{oldRequeteDialyse?.id ? 'Modifier' : 'Enregistrer'}}  </button>

                        <button type="button" class="btn btn-md btn-print custom_btn_primary pull-right"
                         [class.auth-spinner]="loading" (click)="generateRequeteDialyse(itemToSave?.id ? itemToSave : oldRequeteDialyse)"
                            [disabled]="!itemToSave?.id">
                            Imprimer </button>

                        <button type="button" class="btn btn-md btn-cancel pull-right"
                            (click)="closeFormulaire()">Fermer</button>
                    </div>

                </div>
            </ng-container>
        </div>


        <div class="print-part-bilan" id="print-part-bilan">
            <div class="details-header">
              <b> Prévisualisation</b>
        
              <i class="fa fa-times" (click)="previewPrint(false)"></i>
            </div>
            <div class="col-12 details-body" *ngIf="linkPdfFile">
              <object [data]="itemToSave.fileUrlFinale" type="application/pdf" style="height: 388px; width: 100%" id="objPdf"
                internalinstanceid="">
                <embed [src]="itemToSave.fileUrlFinale" type="application/pdf" style="height: 388px; width: 100%"
                  id="embedPdf" />
              </object>
            </div>
          </div>

    </div>

</ng-container>