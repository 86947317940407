import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";

@Component({
  selector: 'app-form-enregistrer-livraison',
  templateUrl: './form-enregistrer-livraison.component.html',
  styleUrls: ['./form-enregistrer-livraison.component.scss']
})
export class FormEnregistrerLivraisonComponent implements OnInit {

  @Input() itemDemande : any = {};
  @Output() close: EventEmitter<number> = new EventEmitter();

  user : any = {};
  listeMedicamentsDemandee : any = [];
  itemsPharmacieMagasin : any = [];
  itemToSave : any = {};
  loading : boolean;
  busyGet: Subscription;
  
   constructor(
    private authService: AuthService,
    private restClient: RestClientService,
    private utilities: UtilitiesService
  ) {
    this.user = this.authService.currentUserValue;
  }

 
  confirmSaveItem() {

    // Verifier si l'observation est renseigné
    if(!this.itemToSave.magasinId) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez sélectionner un magasin !",
        "bottom",
        "center"
      );
      return;
    }

    // Vérifier si les articles pour lesquels on a renseigné des quantités on des qte en stock
    let articleLivres = _.filter(this.listeMedicamentsDemandee, (o) => { return parseInt(o.quantite) > 0 });

    if(!articleLivres || articleLivres.length == 0) {
      this.utilities.showNotification( "snackbar-danger","Veuillez renseigner les quantités des articles livrés !","bottom","center");
      return;
    }

    // Verifier si les qté en stock sont suffisantes
    let hasArticleQteInsuffisant = _.find(articleLivres, (o) => { return parseInt(o.quantite) > parseInt(o.quantiteStock) });
    if(hasArticleQteInsuffisant) {
      this.utilities.showNotification( "snackbar-danger","Veuillez vérifier si les qté en stock sont suffisantes pour effectuer cette livraison !","bottom","center");
      return;
    }

    // Verifier si les qte saisies ne sont pas superieur à la quantité restante
    let hasArticleWhereQteLivreeSupQteCmd = _.find(articleLivres, (o) => { return parseInt(o.quantite) > parseInt(o.quantiteRestante) });

    console.log(" hasArticleWhereQteLivreeSupQteCmd ",hasArticleWhereQteLivreeSupQteCmd);
    console.log(" articleLivres ",articleLivres);
    
    
    if(hasArticleWhereQteLivreeSupQteCmd) {
      this.utilities.showNotification( "snackbar-danger","Les quantités livrées ne doivent pas être supérieur à la quantité restante !","bottom","center");
      return;
    }

    let objToSave = {
      gestMatDemandeServiceId : this.itemDemande.id || null,
      uniteFoncId : this.itemDemande.uniteFoncId,
      Obersvation : this.itemToSave?.observation,
      observation : this.itemToSave.observation,
      datasMedicaments : this.listeMedicamentsDemandee
    }

    Swal.fire({
      title: "Livraison de produit",
      text: "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.SaveItem(objToSave);
      }
    });
  }



  getItemsPharmacieMagasin() {

    let request = {
      user: this.user.id,
      data: {
      },
    }

    this.busyGet = this.restClient.post('pharmacieMagasin/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.itemsPharmacieMagasin = res['items'];
          }
          else {
            this.itemsPharmacieMagasin = [];
          }
        },
        err => {
        }
      );
  }

  SaveItem(item?) {

    this.loading = true;
    var request = {
      user: this.user.id,
      datas: [item],
    };

    this.busyGet = this.restClient.post('gestMatLivraison/create', request).subscribe(
      (res) => {
        this.loading = false;
        if (!res["hasError"]) {
          if (res["items"] && res["items"].length > 0) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
           
           this.closeModal();
          }
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.utilities.showNotification(
              "snackbar-danger",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
          }
        }
      },
      (err) => {
        this.utilities.showNotification(
          "snackbar-danger",
          this.utilities.getMessageEndPointNotAvailble(),
          "bottom",
          "center"
        );
        this.loading = false;
      }
    );
  }

  closeModal() {
    this.close.emit();
  }


  getQuantiteArticleByMagasin() {

    if (!this.itemToSave?.magasinId) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un magasin !",
        "bottom",
        "center");
      return;
    }

    if (!this.listeMedicamentsDemandee || this.listeMedicamentsDemandee.length == 0) {
      this.utilities.showNotification("snackbar-danger", "Aucun médicament commandé !",
        "bottom",
        "center");
      return;
    }

    let listIdMedoc = [];

    this.listeMedicamentsDemandee.forEach(element => {
      listIdMedoc.push(
        {
          id: element?.medicamentId
        }
      )
    });

    let request = {
      user: this.user.id,
      datas: [
        {
          "magasinId": this.itemToSave?.magasinId,
          "datasMedicament": listIdMedoc
        }
      ]
    }

    this.busyGet = this.restClient.post('pharmacieStockMedicament/getMagasinsMedicament', request)
      .subscribe(
        res => {
         
          if (res && res['items']) {
            // this.itemsPharmacieMagasin = res['items'];

            console.log(" livraison au fournisseur ",res);
            
            // Parcourir la liste pour avoir les quantité en stock
            if (res['items'].length > 0) {

              let listeItems = res['items'];

              this.listeMedicamentsDemandee.forEach(prescription => {

                let currentMedocInMagasin = _.find(listeItems, (o) => { return o.medicamentId == prescription.medicamentId });

                if (currentMedocInMagasin && currentMedocInMagasin.id) {
                  prescription.quantiteStock = currentMedocInMagasin.quantiteStock;
                } else {
                  prescription.quantiteStock = 0;
                }

                prescription.magasinId = this.itemToSave?.magasinId;
              });
            } else {
              this.listeMedicamentsDemandee.forEach(prescription => {
                prescription.quantiteStock = 0;
                prescription.magasinId = this.itemToSave?.magasinId;
              });
            }

          }
          else {
            // Chaque quantité en stock vaut 0
            this.listeMedicamentsDemandee.forEach(prescription => {
              prescription.quantiteStock = 0;
            });

          }

        },
        err => {
        }
      );

  }


  ngOnInit(): void {

    console.log(" this.itemDemande ",this.itemDemande);
    
    if(this.itemDemande) {
      this.listeMedicamentsDemandee = this.itemDemande?.medicaments;

      this.listeMedicamentsDemandee.forEach(medoc => {
          medoc.prixUnitaire = parseInt(medoc.prixUnitaire)
          medoc.uniteFoncId = this.itemDemande.uniteFoncId;
      });
    }

    this.getItemsPharmacieMagasin();
  }

}
