import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";

@Component({
  selector: 'app-main-liste-accueil-by-etape',
  templateUrl: './main-liste-accueil-by-etape.component.html',
  styleUrls: ['./main-liste-accueil-by-etape.component.scss']
})
export class MainListeAccueilByEtapeComponent implements OnInit {

   //Declaration de la variable d'emission des valeurs
  @Output() acceuilSelected = new EventEmitter<any>();
  @Input() refreshItem : any;
  @Input() isNotOphtalmoPriseConstante : boolean = false;
  @Input() title : any;
  @Input() isNeedBulletinCadre : boolean = false;
  @Input() hasNeedPlusColonne : any;
  @Input() hasNeedEtapeCircuit : any;
  @Input() hasNumeroDossier : any;
  @Input() notCanTermineConsultation : boolean = false;
  @Input() etapeCircuit : any;
  @Input() etapeCircuit2 : any;
  @Input() praticienId : any;
  @Input() uniteFonctionnelleId : any;
  @Input() isCaisseView : boolean = false;
  @Input() isArchiveView : boolean = false;
  @Input() isDisplayItemViewCaisse : boolean = false; // Ce parametre nous permet de masquer le tr lorsque nous sommes à la caisse
  @Input() ignoreFilterByDate : boolean = false; // Ce parametre nous permet d'ignorer la recherche par le matricule
  
  @Input() canTranfered : boolean = false;
  @Input() isSeeHistoConsultation:any;
  @Input() isHistoriqueConsultation : boolean;
  @Input() patient : any;
  @Input() typeActeCode : any;
  @Input() noLogicCheck : any;
  @Input() isLaboEdition : any;
  @Input() adminUniteFonctionnelleCode : any;
  @Input() isForceFilterByDate : boolean = false;
  itemToSave : any = {};

  modalRef: BsModalRef;

  listeBulletins : Array<any> = [];
  listItems: Array<any> = [];
  items: Array<any> = [];
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: number;
  busyGet: Subscription;
  busySave : Subscription;
  loading: boolean = false;
  itemSelected : any = {};
  listMedecins : any = [];
  listeUniteFonctionnelle : any = [];

  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
  }


  changePaginationSize($event) {
    if($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }
  
  
  pageChanged(event: any): void {
    this.currentPage = event.page ;
    this.getItems();
  }

  seletedItem(accueil : any){
    if(accueil){
      this.itemSelected = accueil;
      this.acceuilSelected.emit(accueil);
    }
  }

  getDetailsBulletin(item) {

    var request = {
      user: this.user.id,
      data: {
        consultationId : item?.id
      }
    }
    
    this.busyGet = this.restClient.post('exCompBulletinActeExterne/getByCriteria', request)
      .subscribe(
        res => {
          // console.log("resul apres generation", res);
          this.itemToSearch.isGenerate = true;

          if (!res['hasError']) {
            // this.utilities.showNotification("snackbar-success",
            //     this.utilities.formatMsgServeur(res['status']['message']),
            //     "bottom",
            //     "center");

            this.listeBulletins = res['items'];

          } else {
            if (res['status'] && res['status']['message']) {
              // this.utilities.showNotification("snackbar-danger",
              //   this.utilities.formatMsgServeur(res['status']['message']),
              //   "bottom",
              //   "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }
  
  getMedecin() {
      let request = {
        user: this.user.id,
        data: {
          // isMedecin: true 
          uniteFonctionnelleId : this.itemToSave.uniteFonctionnelleSelected
        }
      }
  
      this.busySave = this.restClient.post('user/getMedecins', request)
        .subscribe(
          res => {
            if (res && res['items']) {
              console.log(" utilisateur ",res);
              this.listMedecins = _.orderBy(res['items'], ['nom'], ['asc']); 
            }
            else {
              this.listMedecins = [];
            }
          },
          err => {
          }
        );
    }

     confirmSaveItem() {
    
        if(!this.itemToSave.uniteFonctionnelleSelected) {
          this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner l'unité fonctionnelle !",
          "bottom",
          "center");
          return;
        }
    
        if(!this.itemToSave.medecinSelectedId) {
          this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le medecin !",
          "bottom",
          "center");
          return;
        }
    
        let requestUpdate = {
            uniteFoncId : this.itemToSave.uniteFonctionnelleSelected,
            praticienId : this.itemToSave.medecinSelectedId,  
            id : this.itemToSave?.id
        }
        
    
        Swal.fire({
          title: "Transfère de patient",
          text: "Confirmez-vous l'action ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0665aa",
          cancelButtonColor: "#aaa4a4",
          confirmButtonText: "Oui",
          cancelButtonText: 'Non',
        }).then((result) => {
          if (result.value) {
            this.saveTransfert(requestUpdate);
          }
        });
      }
    

  saveTransfert(item) {

    let itemAEnregistrer = Object.assign({}, item);
     
    var request = {
      user: this.user.id,
      datas: [itemAEnregistrer],
    };
    
    this.busySave = this.restClient
      .post( "accueil/update", request)
      .subscribe(
        (res) => {
          console.log("resul", res);

          if (!res["hasError"]) {
            if (res["items"] && res["items"].length > 0) {
              this.utilities.showNotification(
                "snackbar-success",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );

              this.modalRef.hide();
              this.getItems()
            }
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }

     getUniteFonctionnelle() {
        let request = {
          user: this.user.id,
          data: {}
        }
    
        this.busySave = this.restClient.post('adminUniteFonctionnelle/getByCriteria', request)
          .subscribe(
            res => {
              if (res && res['items']) {
                this.listeUniteFonctionnelle = _.orderBy(res['items'], ['libelle'], ['asc']);
              }
              else {
                this.listeUniteFonctionnelle = [];
              }
            },
            err => {
            }
          );
      }


  genererNumDossier(item) {

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      data: {
        id : itemAEnregistrer?.patientId
      }
    }
    
    this.busyGet = this.restClient.post('beneficiaire/generateNumDossier', request)
      .subscribe(
        res => {
          console.log("resul apres generation", res);
          this.itemToSearch.isGenerate = true;

          if (!res['hasError']) {
            this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

             item.numeroDossierRenseigne = res['item']?.numeroDossier;

          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }
  



  confirmTransfert(item) {

    Swal.fire({
      title: "Envoyé en salle de tri",
      text: "Vous êtes sur le point de transferer un dossier. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.transfererDossier(item)
      }
    });
  }
  

  transfererDossier(item) {

    // if(!item?.numeroDossier) {
    //   this.utilities.showNotification("snackbar-danger","Veuillez génerer ou renseigner le numéro de dossier !",
    //   "bottom",
    //   "center");
    //   return;
    // }

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      "data": {
        "id":itemAEnregistrer?.patientId,
        "accueilId":itemAEnregistrer?.id,
        "numeroDossier": (!this.itemToSearch?.isGenerate) ? itemAEnregistrer?.numeroDossierRenseigne : null
      }
    }

    this.busyGet = this.restClient.post('beneficiaire/transfDossier', request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            this.utilities.showNotification("snackbar-success",
            this.utilities.formatMsgServeur(res['status']['message']),
            "bottom",
            "center");

            this.getItems();
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  getItems() {
    
    let request = {
      user: this.user.id,
      data: { 
        // searchString : this.itemToSearch.searchTxt ? this.itemToSearch.searchTxt : null,
        beneficiaireMatricule : this.itemToSearch.searchTxt ? this.itemToSearch.searchTxt : null,
        isCheckLogic: this.noLogicCheck ? false: (( this.etapeCircuit && this.etapeCircuit2) ? true : null),
        etapeCircuit : ( this.etapeCircuit && this.etapeCircuit2) ? null : this.etapeCircuit,
        etapeCircuitParam : null,
        adminTypeActeCode: this.typeActeCode,
        uniteFoncId :  this.uniteFonctionnelleId || null,
        praticienId : this.praticienId,
        orderField:"id",
        orderDirection:"asc",
        createdAt : null,
        createdAtParam : {},
        adminUniteFonctionnelleCode: this.isNotOphtalmoPriseConstante ? "OPHTA" : null,
        adminUniteFonctionnelleCodeParam: this.isNotOphtalmoPriseConstante ? {
            "operator": "<>"
        } : null

      },
      index : this.currentPage - 1,
      size : this.itemsPerPage
    }

    
    if( (this.isCaisseView && !this.etapeCircuit2) || (this.isCaisseView && this.isForceFilterByDate) ) {

      let dateDebut = this.user.lastConnectionDate?.split(" ")[0];

      if((!this.ignoreFilterByDate || !this.itemToSearch.searchTxt)) {
        if (dateDebut ) {
          request.data.createdAt = dateDebut + ' 00:00:00';
          request.data.createdAtParam =
            {
              "operator": "[]",
              "start": dateDebut + ' 00:00:00', //"22/02/2019",
              "end": dateDebut + ' 23:59:59' //"22/02/2019"
            }
        }
      }
    }

    this.busyGet = this.restClient.post('accueil/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.items = res['items'];
            this.totalItems = res['count'];

            if(this.isArchiveView) {
              this.items.forEach(element => {
                element.numeroDossierRenseigne  = element?.numeroDossier
              });
            }
            
          }
          else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        err => {
        }
      );
  }
  
  getExactlyNumberRow(page,index)
  {
      let num = index +1;
      if(page>1)
      {
          num = ((page - 1) * this.itemsPerPage) + (index+1);
      }
      return num;
  }



  confirmEnvoieChezLeMedecin(item) {

    Swal.fire({
      title: "Envoyé chez le médecin",
      text: "Vous êtes sur le point de transferer un dossier. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.transfererDossierChezLeMedecin(item)
      }
    });
  }
  

  transfererDossierChezLeMedecin(item) {

    // if(!item?.numeroDossier) {
    //   this.utilities.showNotification("snackbar-danger","Veuillez génerer ou renseigner le numéro de dossier !",
    //   "bottom",
    //   "center");
    //   return;
    // }

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      "data": {
        "id":itemAEnregistrer?.patientId,
        "accueilId":itemAEnregistrer?.id,
        canSendToThePraticien : true,
        "numeroDossier": (!this.itemToSearch?.isGenerate) ? itemAEnregistrer?.numeroDossierRenseigne : null
      }
    }

    this.busyGet = this.restClient.post('beneficiaire/transfDossier', request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            this.utilities.showNotification("snackbar-success",
            this.utilities.formatMsgServeur(res['status']['message']),
            "bottom",
            "center");

            this.getItems();
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  
  ngOnInit() {
    if(!this.isSeeHistoConsultation) {
      this.getItems();
    }
  }

  ngOnChanges(changes: SimpleChanges) {


    if(changes['refreshItem'] && changes['refreshItem'].currentValue)
    {
      this.currentPage = 1;
      this.getItems();
    }

    if(changes['isHistoriqueConsultation'] && changes['isHistoriqueConsultation'].currentValue) {
      this.itemToSearch.searchTxt = this.patient?.matriculeBeneficiaire;
    }

    console.log(" canTranfered ",this.canTranfered);
    
  }

  confirmTermineConsultation(item) {

    if(!item.consultationId) {
      this.utilities.showNotification("snackbar-danger","Aucune consultation n'est associé à cette ligne !",
      "bottom",
      "center");
      return;
    }
    Swal.fire({
      title: "Terminer Consultation",
      text: "Vous êtes sur le point de terminer une consultation. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        let objToSave : any = {};
        objToSave.isClotured = true;
        objToSave.id = item.consultationId || null;
        this.saveItem(objToSave);
      }
    });
  }


  openModal(data: any, template: TemplateRef<any>) {

    let config = {backdrop: true, ignoreBackdropClick: true};

    this.itemToSave = {};
    if (data) {
      this.itemToSave = Object.assign({}, data);
      this.getUniteFonctionnelle();
    }

    this.modalRef = this.modalService.show(template,Object.assign({},config, { class: 'modal-md' }));
  }

  fermertureFormulaire(){
    this.modalRef.hide();
  }



  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ]
    }

    // console.log(" request ",request);
    // return;


    this.busyGet = this.restClient.post('ucConsultation/' + (!itemAEnregistrer?.id ? 'create' : 'update'), request)
      .subscribe(
        res => {

          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

              this.getItems();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }
  
}
