<ng-container>
    <div class="row" [ngBusy]="{
        busy: busyGet,
        message: 'Veuillez patienter ...',
        backdrop: false
      }">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <table class="table table-hover table-striped table-condensed">
          <thead>
            <tr *ngIf="title && !isHistoriqueConsultation">
              <th colspan="11" style="text-align: center; background-color: #fff">
                <h2 class="titla">
                  {{ title }}
                </h2>
              </th>
            </tr>
            <tr *ngIf="!isHistoriqueConsultation">
              <th colspan="11">

                <div class="row">
                  <div class="col-md-4 mt-3" *ngIf="!hasNeedPlusColonne">
                    <input [(ngModel)]="itemToSearch.searchTxt" class="form-control custom-input pull-left"
                      placeholder="Rechercher ..." (keyup)="currentPage = 1; getItems()" />
                  </div>
    
                  <div class="col-md-3 mt-3" *ngIf="hasNeedPlusColonne">
                    <input [(ngModel)]="itemToSearch.searchTxt" class="form-control custom-input"
                      placeholder="Rechercher ..." (keyup)="currentPage = 1; getItems()" />
                  </div>
  
                  <div class="col-md-2 mt-3 pull-right" *ngIf="needFilterByDate">
                    <input class="form-control custom-input" placeholder="Période" autocomplete="off"
                        [(ngModel)]="itemToSearch.dateRecherche" name="dateRecherche" placement="bottom" type="text"
                        bsDaterangepicker (bsValueChange)="onDateRangeChange($event)" [maxDate]="dateActuelle"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-default' }">
                  </div>
                </div>
               

              </th>
            </tr>
            <tr class="custom-table-head">
  
              <th style="width: 50px" class="txt-align-center">
  
                <a (click)="currentPage = 1; getItems()" title="Actualiser">
                  <i class="fa fa-redo-alt" style="font-size: 10px"></i></a>&nbsp;&nbsp;
              </th>
              <th class="txt-align-center" *ngIf="hasNeedPlusColonne">Photo</th>
              <th>N° Accueil</th>
              <th>Date & Heure</th>
              <th>Matricule</th>
              <th>Patient</th>
              <!-- <th *ngIf="isCaisseView">Type acte</th> -->
              <!-- <th *ngIf="!isCaisseView">Désignation de l'acte</th>
              <th *ngIf="hasNeedPlusColonne">Unité fonctionnelle</th> -->
              <th *ngIf="hasNeedEtapeCircuit"  class="txt-align-center">Etape circuit</th>
             
              <th *ngIf="hasNeedPlusColonne" class="txt-align-center">Action</th>
            </tr>
          </thead>
  
          <tbody>
            <tr *ngFor="let item of items; let indice = index" style="cursor: pointer"
              [class.rowActive]="item?.id == itemSelected?.id" (click)="seletedItem(item)">
  
  
              <td class="txt-align-center">
                {{ getExactlyNumberRow(this.currentPage, indice) }}
              </td>
              <td *ngIf="hasNeedPlusColonne" class="txt-align-center">
                <img
                  src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                  alt="Photo assuré" class="img-assure" />
              </td>
              <td>{{ item?.code }}</td>
              <td>{{ item?.createdAt }}</td>
              <td>{{item?.dataProgrammeSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.matriculeBeneficiaire}}</td>
              <td>{{item?.dataProgrammeSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.nom +' - '+item?.dataProgrammeSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.prenom }}</td>
             
              <!-- <td *ngIf="isCaisseView">{{ item?.adminTypeActeLibelle }}</td>
              <td *ngIf="!isCaisseView">
  
                <span *ngFor="let it of item?.actes">{{ it?.libelle }}</span>
              </td>
              <td *ngIf="hasNeedPlusColonne">
                {{ item?.adminUniteFonctionnelleLibelle }}
              </td> -->
  
              <td *ngIf="hasNeedEtapeCircuit" class="txt-align-center">
                {{ item?.etapeCircuit || '-' }}
              </td>

              <td class="txt-align-center">
                <button type="button" class="btn btn-xs btn-default custom_btn_primary" title="Cliquez pour sélectionner"
                  *ngIf="!hasNumeroDossier">
                  Sélectionner
                </button>
                <button type="button" class="btn btn-xs btn-default custom_btn_primary" title="Cliquez pour sélectionner"
                  *ngIf="hasNumeroDossier" (click)="transfererDossier(item)">
                  Transférer
                </button>
              </td>
            </tr>
  
          </tbody>
  
          <tfoot>
            <tr *ngIf="items && items.length > 0" style="border: 1px solid #fff;">
              <td colspan="11">
                <div class="row pagination-part">
                  <div class="col-md-9" style="text-align: center">
                    <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                      [(ngModel)]="currentPage" class="pagination-sm" previousText="&lsaquo;" nextText="&rsaquo;"
                      firstText="&laquo;" lastText="&raquo;" (pageChanged)="pageChanged($event)"></pagination>
                  </div>
  
                  <div class="col-md-3">
                    <div class="paginator-per-page">
      
                      <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                        [defaultItemPerPage]="itemsPerPage">
                      </app-element-by-page-paginator>
      
                      <span class="span_total_entree pull-right">Total : {{
                        totalItems
                        }}</span>
      
                    </div>
                  </div>
                  
                </div>
              </td>
            </tr>
  
            <tr *ngIf="!items || items.length == 0" style="border: 1px solid #fff;">
              <td colspan="11">
                <div class="col-md-12 txt-align-center">
                  Aucune données disponible
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-container>