<ng-container>
    <div class="row" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
        <div class="col-12 mb-3" *ngIf="itemToSave?.createdAt && itemToSave?.enregistrerPar">
            <b class="pull-right derniere-info"> Dernière modification le {{itemToSave?.createdAt}} par
                {{itemToSave?.enregistrerPar}} </b>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-2 mb-2">
                    <label>N° Certificat :
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="N° ordonnance"
                        autocomplete="off" [(ngModel)]="itemOldOrdonance.numeroOrdonnance" name="numeroOrdonnance"
                        [disabled]="true" required type="text" style="font-size: 9px !important;font-weight: bold;">
                </div>

                <div class="col-2 mb-2">
                    <label>Date et Heure Cons:
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="Date et Heure Cons"
                        autocomplete="off" [(ngModel)]="consultation.createdAt" name="createdAt" [disabled]="true"
                        required type="text">
                </div>

                <div class="col-2 mb-2">
                    <label>N° Consultation :
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="N°Consultation"
                        autocomplete="off" [(ngModel)]="consultation.numeroConsultation" name="numeroConsultation"
                        [disabled]="true" required type="text">
                </div>


                <div class="col-3 mb-2">
                    <label>Medecin traitant :
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="Medecin traitant"
                        autocomplete="off" [(ngModel)]="currentPatient.medecin" name="medecin" [disabled]="true"
                        required type="text">
                </div>

                <div class="col-3 mb-2">
                    <label>Unité fonctionnelle :
                    </label>
                    <input class="form-control custom-input custom-input-details-cons" placeholder="Unité fonctionnelle"
                        autocomplete="off" [(ngModel)]="currentPatient.adminUniteFonctionnelleLibelle"
                        name="adminUniteFonctionnelleLibelle" [disabled]="true" required type="text">
                </div>
            </div>
        </div>

        <fieldset class="custom-fieldset mb-2">
            <legend> Info patient </legend>

            <div class="col-12">
                <app-info-patient-consultation [currentPatient]="currentPatient" [withAssurances]="true">
                </app-info-patient-consultation>
            </div>

        </fieldset>



        <fieldset class="custom-fieldset ">
            <legend> Info certificat </legend>

            <div class="row">
                <div class="col-md-3 col-sm-12">
                    <div class="row">

                        <div class="col-12 mb-2">
                            <label>Type de document :<span class="required_champs">*</span>
                            </label>
                            <select class="form-select custom_input" id="typeDocumentsSelected"
                                [(ngModel)]="itemToSave.typeDocumentsSelected" (change)="getDescription()"
                                name="typeDocumentsSelected">
                                <option value="">-- Choisissez --</option>
                                <option *ngFor="let item of listeTypeDocuments" [ngValue]="item">
                                    {{ item?.libelle }}
                                </option>
                            </select>
                        </div>

                        <div *ngIf="!itemToSave?.id">
                            <ng-container *ngFor="let sor of listeChamps;">

                                <div class="col-12 mb-2">
                                    <label>{{sor?.libelle}} :<span class="required_champs">*</span></label>

                                    <input class="form-control custom-input" placeholder="{{sor?.libelle}}"
                                        autocomplete="off" [(ngModel)]="sor.nomChamp1" name="{{sor?.nomChamp}}"
                                        [ngModelOptions]="{standalone:true}"
                                        (keyPress)="formatChamp(sor?.nomChamp,sor?.nomChamp1,sor.type)"
                                        (keyUp)="formatChamp(sor?.nomChamp,sor?.nomChamp1,sor.type)"
                                        (change)="formatChamp(sor?.nomChamp,sor?.nomChamp1,sor.type)" type="text"
                                        *ngIf="sor.type == 'text' ">
                                    <input class="form-control custom-input" placeholder="{{sor?.libelle}}"
                                        autocomplete="off" [(ngModel)]="sor.nomChamp1" name="{{sor?.nomChamp}}"
                                        [ngModelOptions]="{standalone:true}"
                                        (keyPress)="formatChamp(sor?.nomChamp,sor?.nomChamp1,sor.type)"
                                        (keyUp)="formatChamp(sor?.nomChamp,sor?.nomChamp1,sor.type)"
                                        (change)="formatChamp(sor?.nomChamp,sor?.nomChamp1,sor.type)" type="number"
                                        *ngIf="sor.type == 'number' ">

                                    <input class="form-control custom-input" placeholder="{{sor?.libelle}}"
                                        autocomplete="off" [(ngModel)]="sor.nomChamp1" name="{{sor.nomChamp}}"
                                        [ngModelOptions]="{standalone:true}"
                                        (bsValueChange)="formatChamp(sor?.nomChamp,$event,sor.type)"
                                        *ngIf="sor.type == 'date'" type="text" bsDatepicker
                                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' }" />

                                    <!-- <input class="form-control custom-input" placeholder="{{sor?.libelle}}" autocomplete="off" [(ngModel)]="sor.nomChamp" name="{{sor?.nomChamp}}" [ngModelOptions]="{standalone:true}" (keyPress)="formatChamp({{sor?.nomChamp}})"  (keyUp)="formatChamp({{sor?.nomChamp}})"  (change)="formatChamp({{sor?.nomChamp}})" type="text" *ngIf="sor.type == 'text' "> -->
                                </div>

                            </ng-container>

                            <div class="col-12 mt-2" *ngIf="listeChamps && listeChamps.length > 0">
                                <button type="button" class="btn btn-md btn-primary" (click)="getDescription()"> <i
                                        class="fa fa-refresh"></i> Réinitialiser les valeurs</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-md-9 col-sm-12">
                    <!-- <div [innerHtml]="itemDescription"></div> -->

                    <ckeditor [(ngModel)]="itemDescription" name="ckedi" [config]="{uiColor: '#eceeee'}"
                        [readonly]="false">
                    </ckeditor>

                </div>


            </div>

        </fieldset>


        <div class="row" *ngIf="!isHistoConsultation">
            <ng-container>
                <div class="btn-manger-part">

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding: 0;">

                        <button type="button" class="btn btn-md custom_btn_primary pull-right"
                            (click)="confirmSaveItem(itemToSave,(itemToSave.typeDocumentsSelected?.isValidation || false))"
                            [class.auth-spinner]="loading" [disabled]="loading" *ngIf="!itemToSave?.id">
                            {{ itemToSave.typeDocumentsSelected?.isValidation ? 'Enregistrer et envoyer en validation':
                            'Enregistrer' }} </button>

                        <button type="button" class="btn btn-md custom_btn_primary pull-right"
                            (click)="confirmSaveItem(itemToSave)" [class.auth-spinner]="loading" [disabled]="loading"
                            *ngIf="itemToSave?.id">
                            Modifier </button>

                        <button type="button" class="btn btn-md btn-print custom_btn_primary pull-right"
                            (click)="generateCertificat()" [class.auth-spinner]="loading"
                            [disabled]="(loading || !consultation?.id || !itemToSave?.id)">
                            Imprimer le certificat </button>

                        <button type="button" class="btn btn-md btn-cancel pull-right"
                            (click)="closeFormulaire()">Fermer</button>
                    </div>

                </div>
            </ng-container>
        </div>
    </div>

</ng-container>


<div class="print-part-certificat" id="print-part-certificat">
    <div class="details-header">
        <b> Prévisualisation</b>

        <i class="fa fa-times" (click)="previewPrint(false)"></i>
    </div>
    <div class="col-12 details-body" *ngIf="linkPdfFile">
        <object [data]="itemToSave.fileUrlFinale" type="application/pdf" style="height: 388px; width: 100%" id="objPdf"
            internalinstanceid="">
            <embed [src]="itemToSave.fileUrlFinale" type="application/pdf" style="height: 388px; width: 100%"
                id="embedPdf" />
        </object>
    </div>
</div>