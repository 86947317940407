import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { last, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from 'sweetalert2';
import * as _ from "lodash";
import { enumStatut } from 'src/app/core/enumerator/enumerator';
@Component({
  selector: 'app-detail-visite-medecin',
  templateUrl: './detail-visite-medecin.component.html',
  styleUrls: ['./detail-visite-medecin.component.scss']
})
export class DetailVisiteMedecinComponent implements OnInit {

  @Input() patientId: string;
  @Input() dossierHospi:any
  @Input() detailToShow:any
  @Output() onSelectCategorieClient: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDatasAssurances: EventEmitter<any> = new EventEmitter();
  user: any = {};
  busyGet: Subscription;
  loading: boolean = false;
  endPoint : string = 'beneficiaire/';
  itemToSave:any={}
  beneficiaireInfo: any={};
  itemsHistoPriseDeConstante: any=[];
  medecinFullName: any;
  beneficiaireInfoAssurance: any;
  itemsCategorie: any;
  itemsAssurances: any[];
  currentAssurance: any;
  listAssurancesToSave: any=[];
  oldAssur: any =[];
  imgUrl: string;
  listConstantePatient = []
  currentItemPatient: any = {};
  totalItems: number;
  currentPage: number = 1;
  itemsPerPage: number = 3;
  currentPatientSelected : any = {};
  modalRef: BsModalRef;
  detailsPriseDeConstante: any;
  currentPatient: any;
  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
    
  }
  ngAfterViewInit(){
    console.log('dossierHospi',this.dossierHospi);
    
  }
  ngOnChanges(changes: SimpleChanges) {

    if(changes['dossierHospi'] && changes['dossierHospi'].currentValue)
    {
      this.dossierHospi = changes['dossierHospi'].currentValue
      this.itemToSave = this.dossierHospi
      console.log('data passed dossierHospi: ',this.dossierHospi);
      this.itemToSave.noDemande = this.itemToSave.hospiNumeroDemande || this.itemToSave.numeroDemande
      this.itemToSave.dateDossier = this.itemToSave.dateCreationDossier?this.itemToSave.dateCreationDossier:this.itemToSave.dateHospi
      this.itemToSave.patient = this.itemToSave.nomPatient+' '+this.itemToSave.prenomPatient



      this.itemToSave.uniteFoncLibelle = this.itemToSave.uniteFoncLibelle || this.itemToSave.uniteFonctionnelleLibelle
      this.itemToSave.matricule  = this.itemToSave.matricule || this.itemToSave.matriculeBeneficiaire
      this.itemToSave.medecinTraitant = this.itemToSave.medecinTraitant || this.itemToSave.userNom+' '+this.itemToSave.userPrenom
      this.itemToSave.sexe=this.itemToSave.sexe??this.itemToSave.sexePatient

      if(this.dossierHospi.datasAssurance || this.dossierHospi.assurances){
        this.listAssurancesToSave=[...this.dossierHospi.datasAssurance??this.dossierHospi.assurances]
        this.listAssurancesToSave.map(lats=>lats.isOld=true)
        this.oldAssur = [...this.listAssurancesToSave]
        console.log('preselected: ',this.listAssurancesToSave);
      }

      this.imgUrl = this.dossierHospi.imgUrl
      if(!this.imgUrl && this.dossierHospi.matricule){
        this.getInfoBenef(this.dossierHospi.matricule)
      }

      

    }
  }
  

  getDatasCategorieClient() {

    let request = {
      user: this.user.id,
      data: {
        
      },
    }
    console.log('data sent to server',request);
    this.busyGet = this.restClient.post('adminCategorieClient/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.itemsCategorie = res['items']
            console.log('this.itemsCategorie: ',this.itemsCategorie);
            
          }
          else {
            this.itemsCategorie = [];
          }
        },
        err => {
        }
      );
  }


  getHistoPriseDeConstante() {
    
    if(!this.dossierHospi.patientId){
      return
    }
    let request = {
      user: this.user.id,
      data: {
        dossierHospiId: this.dossierHospi.id
      },
    }

    console.log('data sent to server',request);
    
    this.busyGet = this.restClient.post('hospiPriseConstante/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.itemsHistoPriseDeConstante = res['items'];
          }
          else {
            this.itemsHistoPriseDeConstante = [];
          }
        },
        err => {
        }
      );
  }

  onSelectCategorie(){
    this.onSelectCategorieClient.emit(this.itemToSave.categorieClientId)
  }

  onSelectAssaurance(){
    this.onSelectDatasAssurances.emit(this.listAssurancesToSave)
  
  }
  getItemsAssurance(){
    let request = {
      user: this.user.id,
      data: {
      },

    }
    console.log('data sent to server: ',request);
    this.busyGet = this.restClient.post('adminAssurance/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.itemsAssurances = res['items'];
          }
          else {
            this.itemsAssurances = [];
          }
        },
        err => {
        }
      );
  }

  addAssurance() {

    console.log('assurance select: ',this.currentAssurance);
    console.log('all assur: ',this.listAssurancesToSave);
    

    if (!this.currentAssurance) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner une assurance !",
        "bottom",
        "center");
      return;
    }

    // Verifier si ce acte n'existe pas dans le tableau
    let isExisteAssurance = _.find(this.listAssurancesToSave, (o) => { return (o.id == this.itemToSave?.assuranceSelected?.id || o.id == this.currentAssurance?.id) });

    if (isExisteAssurance) {
      // Dans ce cas l'acte est deja dans le tableau
      this.utilities.showNotification("snackbar-danger", "Cette assurance a déjà été sélectionnée !",
        "bottom",
        "center");
      return;
    }


    this.listAssurancesToSave.push(this?.currentAssurance);
    this.currentAssurance = "";

    // this.getMontantPartAssurance();
  }

  getInfoBenef(matricule:any){
    let request = {
      user: this.user.id,
      data: {
        matriculeBeneficiaire: matricule
    }

    }
    console.log('data sent to server: ',request);
    this.busyGet = this.restClient.post('beneficiaire/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.imgUrl = res['items'][0].imgUrl;
          }
          else {
            this.imgUrl=null
          }
        },
        err => {
        }
      );
  }

  handleError(){
    this.imgUrl="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
  }

  getPriseConstante() {
    let request = {
      user: this.user.id,
      data: {
        // isMedecin: true
        // patientId: this.itemToSave?.idPatient,
        dossierHospiId:this.itemToSave.dossierHospiId
        // type : enumStatut?.PRISE_DE_CONSTANTE_GENERALISTE
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage
    }

    this.restClient.post('hospiPriseConstante/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listConstantePatient = res['items'];
            this.totalItems = res['count'];
          }
          else {
            this.listConstantePatient = [];
            this.totalItems = 0;
          }
        },
        err => {
        }
      );
  }
  
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getPriseConstante();
  }
  openFormPriseConstante(template: TemplateRef<any>,item) {
    let config = { backdrop: true, ignoreBackdropClick: true, id: 1, class: 'modal-width-90' };

    this.currentPatientSelected = {...item}
    this.modalRef = this.modalService.show(template, config);
    this.modalRef.onHide.subscribe((res) => {
      this.getPriseConstante()
    });
  }

  openModal(data: any, template: TemplateRef<any>, isBilanForm?, isHistoConsultation?,isConsultationHistoSalleTri?:boolean) {

    let config = { backdrop: true, ignoreBackdropClick: true, id: 1, class: !isConsultationHistoSalleTri ? ((isBilanForm || isHistoConsultation) ? 'modal-width-90' : 'modal-praticien') :'modal-lg'  };

    if (data) {
      // Lorsque nous sommes en modification
      this.detailsPriseDeConstante = { ...data };
      this.currentPatient = { ...data };
      console.log(" detailsPriseDeConstante ",this.detailsPriseDeConstante);
    }

    this.modalRef = this.modalService.show(template, config);
  }
 
  
  ngOnInit() {
    this.getDatasCategorieClient() 
    this.getItemsAssurance()
    this.getPriseConstante()
  }
}
