<ng-container>
    <div class="row search-part">

        <div class="col-md-2 col-sm-2 mb-0">
            <label>Matricule :
            </label>
            <input class="form-control custom-input"
                placeholder="Rechercher par le matricule ou le nom du patient" autocomplete="off"
                [(ngModel)]="itemToSearch.searchText" (keyup)="getItems()" name="searchText" type="text">
        </div>

        <div class="col-md-8 col-xs-12 col-sm-12"
            *ngIf="itemSelected && (itemSelected?.id || itemSelected?.numeroCarte)" style="margin: 0;">

            <div class="row">
            

                <div class="col-md-6 col-sm-6">
                    <label>Nom & prénoms :
                    </label>
                    <input class="form-control custom-input" placeholder="Nom et prénoms" autocomplete="off"
                        [(ngModel)]="itemSelected.identite" name="identite" [disabled]='true' type="text">
                </div>

                <div class="col-md-1 col-sm-12">
                    <label>Genre:
                    </label>
                    <input class="form-control custom-input" placeholder="Genre" autocomplete="off"
                        [(ngModel)]="itemSelected.sexe" name="sexe" [disabled]='true' type="text">
                </div>

                <div class="col-md-3 col-sm-3">
                    <label>Age :
                    </label>
                    <input class="form-control custom-input" placeholder="Age" autocomplete="off"
                        [(ngModel)]="itemSelected.age" name="dateNais" [disabled]='true' type="text">
                </div>

                <div class="col-md-2 col-sm-12">
                    <label>Catégorie client :
                    </label>
                    <input class="form-control custom-input" placeholder="Catégorie" autocomplete="off"
                        [(ngModel)]="itemSelected.codeTypeRisque" name="codeTypeRisque"
                        [disabled]='true' type="text">
                </div>
            </div>
        </div>

        <div class="col-md-2 col-sm-2" *ngIf="itemSelected && itemSelected?.numeroCarte"
            style="margin-bottom: 0;text-align:right">
            <img [src]="itemSelected.imgUrl" (error)="handleError(itemSelected)" alt="Photo assuré"
                class="pull-right img-assure" style="width: 120px;height: 120px;" *ngIf="itemSelected.imgUrl" />
        </div>

    </div>
</ng-container>

