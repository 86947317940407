import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/service/auth.service";
import { RestClientService } from "src/app/core/service/rest-client.service";
import { UtilitiesService } from "src/app/core/service/utilities.service";
import Swal from "sweetalert2";
import * as _ from "lodash";
import { enumStatut } from "src/app/core/enumerator/enumerator";

@Component({
  selector: 'app-historique-demandes-services',
  templateUrl: './historique-demandes-services.component.html',
  styleUrls: ['./historique-demandes-services.component.scss']
})
export class HistoriqueDemandesServicesComponent implements OnInit {

  items: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  busyGet: Subscription;
  busySave: Subscription;
  listCaisier: Array<any> = [];
  medicaments: Array<any> = [];
  loading: boolean = false;
  itemsMedicament: Array<any> = [];
  itemsUniteFonctionnelle : any = [];
  currentUniteFonct : any = {};
  statutEnum : any = {};

  @Input() isLivraisonAuService : boolean = false;

  constructor(
    private authService: AuthService,
    private restClient: RestClientService,
    private modalService: BsModalService,
    private utilities: UtilitiesService
  ) {
    this.user = this.authService.currentUserValue;
    // this.currentUniteFonct.libelle = this.user?.currentFonctionnaliteSelected?.libelleUniteFonctionnelle;
    // this.currentUniteFonct.id = this.user?.currentFonctionnaliteSelected?.idUniteFonctionnelle;
    this.currentUniteFonct = this.authService.getActiveUniteFonctionnelle()
    this.statutEnum = enumStatut;
  }


  changePaginationSize($event) {
    if($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }


  getItems(uniteFocntId ?) {

    // if(!this.isLivraisonAuService){
    //   uniteFocntId = this.user?.uniteFoncActiveId;
    // }

    let request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSearch.libelle,
        uniteFoncId : !this.isLivraisonAuService?this.currentUniteFonct.idUniteFonctionnelle : null,
        statut :this.isLivraisonAuService ? enumStatut.EN_ATTENTE_DE_LIVRAISON : null,
        // statut :'ATTENTE LIVRAISON',

        isLivre :false
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage,
    };

    this.busyGet = this.restClient
      .post("gestMatDemandeService/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.items = res["items"];
            this.totalItems = res["count"];
          } else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        (err) => {}
      );
  }

  onToggle(it) {
    let tempon = it.isDetailsOpen;
    this.items.forEach((element) => {
      element.isDetailsOpen = false;
    });
    it.isDetailsOpen = !tempon;
    if (it.isDetailsOpen) {
      this.itemsMedicament = it.medicaments;
    }
  }

  getTotal(it) {
    let total = 0;
    if (it && it.length) {
      it.map(
        (d: any) =>
          (total += Number(d.quantiteCommande * d.prixUnitaireMedicament))
      );
    }
    return total;
  }

  openModal(data: any, template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true ,class: "modal-lg modal-width-75"};

     this.itemToSave = {
       uniteFoncId:
         JSON.parse(localStorage.getItem("currentUser"))?.uniteFoncActiveId ||
         null,
     };
     if (data) {
       // Lorsque nous sommes en modification
       this.itemToSave = {
         ...data,
         uniteFoncId:
           JSON.parse(localStorage.getItem("currentUser"))?.uniteFoncActiveId ||
           null,
       };
     }

    this.modalRef = this.modalService.show(
      template, config,
    );
  }

  // Envoyer en validation
  confirmSendValidation(item) {
    Swal.fire({
      title: "Envoi en validation",
      text: "Vous êtes sur le point d'envoyer une demande en validation. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3f51b5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.sendValidation(item);
      }
    });
  }

  sendValidation(obj) {
    var request = {
      user: this.user.id,
      data: { id: obj.id },
    };
    this.busyGet = this.restClient
      .post("gestMatDemandeService/envoyerEnValidation", request)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
            this.currentPage = 1;
            this.getItems();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }


  confirmDelete(item) {
    Swal.fire({
      title: "Annulation de demande de produit",
      text: "Vous êtes sur le point de supprimer une demande de produit. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3f51b5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.deleteItem(item);
      }
    });
  }

  deleteItem(obj) {
    var request = {
      user: this.user.id,
      datas: [{ id: obj.id , statut : enumStatut.ANNULE }],
    };
    this.busyGet = this.restClient
      .post("gestMatDemandeService/update", request)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
            this.currentPage = 1;
            this.getItems();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }

  getUnit() {
    let request = {
      user: this.user.id,
      data: {},
    };
    this.busyGet = this.restClient
      .post("adminUniteFonctionnelle/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.itemsUniteFonctionnelle = _.orderBy(res["items"], ['libelle'], ['asc']);

            // Si nous ne sommes pas a la livraiosn, on gris et on preselectionne l'unité fonctionnelle
            if(!this.isLivraisonAuService) {
               this.itemToSearch.uniteFoncId = this.user?.uniteFoncActiveId;
            }
          } else {
            this.itemsUniteFonctionnelle = [];
          }
        },
        (err) => {}
      );
  }

  pageChanged(event: any): void {
    this.currentPage = event.page ;
    this.getItems();
  }


  ngOnInit(): void {
    this.getUnit();
    this.getItems(!this.isLivraisonAuService ? this.user?.uniteFoncActiveId : null);
  }

}
