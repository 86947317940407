import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAgeRangeMask]'
})
export class AgeRangeMaskDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement) {
    const value = input.value;
    const [fromStr, toStr] = value.split('to').map(part => part.trim());

    let from = parseInt(fromStr, 10);
    let to = parseInt(toStr, 10);

    // Validate parsed numbers and limit them to the range 0-99
    if (isNaN(from) || from < 0 || from > 99) {
      from = 0;
    }
    if (isNaN(to) || to < 0 || to > 99) {
      to = 0;
    }

    // Format the input value
    const formattedValue = `from ${from} to ${to}`;

    // Update the input value
    input.value = formattedValue;

    // Calculate and set the correct cursor position
    const caretPos = this.calculateCaretPosition(input.selectionStart, fromStr.length, toStr.length);
    input.setSelectionRange(caretPos, caretPos);
  }

  private calculateCaretPosition(currentPos: number, fromLength: number, toLength: number): number {
    // Calculate the new caret position based on the lengths of "from" and "to" strings
    const fromToDelimiterLength = 3; // Length of " to "
    const newPosition = (currentPos <= (5 + fromLength)) ? 
                        currentPos :
                        currentPos + fromToDelimiterLength + toLength - fromLength;
    return newPosition;
  }
}
