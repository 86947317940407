import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { enumStatut } from 'src/app/core/enumerator/enumerator';
import { ActivatedRoute } from '@angular/router';
import { FormCreateProgrammeComponent } from 'src/app/bureau-des-entrees/main-accueil-dialyse/programme-dialyse/form-create-programme/form-create-programme.component';

@Component({
  selector: 'app-main-requetes-dialyse',
  templateUrl: './main-requetes-dialyse.component.html',
  styleUrls: ['./main-requetes-dialyse.component.scss']
})
export class MainRequetesDialyseComponent implements OnInit {

  currentAccueilSelected : any = {};
  modalRef: BsModalRef;
  itemPriseDecision : any = {};
  user : any = {};
  loading : boolean;
  refreshItem : any;
  busyGet : Subscription;
  statutEnum : any = {};
  isHistoFromReception : boolean;
  
  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService, private route: ActivatedRoute) {
    this.user = this.authService.currentUserValue;
    this.statutEnum = enumStatut;

    this.route.params.subscribe(params => {
      if (params['isHistoRequeteInRecetion']) {
        this.isHistoFromReception = true;
      }
    });

  }

  openCreateProgramme(requete) {
    let initialState = {
      date: null,
      medecinId: null,
      uniteFoncId: null,
      benefeciaireDefautSelectedId: requete?.dataBeneficiaire?.id,
      requete : requete,
      listeDisponibilite: null /*,
          class: 'details-offres modal-lg'*/,
    };
    this.modalService.show(FormCreateProgrammeComponent, {
      animated: true,
      keyboard: false,
      backdrop: "static",
      ignoreBackdropClick: true,
      initialState,
      class: "gray modal-lg modal-width-75",
    });
    this.modalService.onHide.subscribe((result) => {
      if (result === "Yes") {
        // this.getProgramme();
      }
    });
  }
  
  openModal(data: any, template: TemplateRef<any>,isTraiterDemande ?) {

    let config = {backdrop: true, ignoreBackdropClick: true,id: 1, class: (isTraiterDemande ? 'modal-width-50' :  'modal-width-75') };

    if (data) {
      // Lorsque nous sommes en modification
      this.currentAccueilSelected = {...data};
    }

    this.modalRef = this.modalService.show(template,config);
  }

  fermertureFormulaireModal($event) {
    this.modalRef.hide();

    if($event) {
      this.refreshItem = new Date().getTime();
    }
  }

  confirmTraiterDemande() {

    if(!this.itemPriseDecision.decision) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner une décision !", "bottom", "center");
      return;
    }

    if(this.itemPriseDecision.decision?.toLowerCase() == enumStatut.REJETE?.toLowerCase()) {
      if(!this.itemPriseDecision.motif) {
        this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le motif !", "bottom", "center");
        return;
      }
    }
    Swal.fire({
      title: "Traitement de requete",
      text: "Vous êtes sur le point de "+this.itemPriseDecision.decision+" cette demande. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {

        let objToSave = {
          id: this.currentAccueilSelected?.id,
          statut: this.itemPriseDecision.decision?.toUpperCase(),
          motif : this.itemPriseDecision.libelle,
          validatedBy : this.user.id
        }
        this.saveItem(objToSave);
      }
    });
  }

  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ]
    }

    this.busyGet = this.restClient.post('dialyseRequete/update', request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
              
                this.modalRef.hide();
                this.refreshItem = new Date().getTime();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  
  ngOnInit(): void {
  }

}
