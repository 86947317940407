<ng-container>
  <div [ngBusy]="{
      busy: busyGet,
      message: 'Veuillez patienter ...',
      backdrop: false
    }">
    <div class="row">
      <div class="col-md-12 col-sm-12 mb-2" *ngIf="!isEnvoiALaCaisseOdonto">
        <label>Unité fonctionnelle :
          <span class="required_champs">*</span>
        </label>
        <ng-select [(ngModel)]="itemToSave.uniteFonctionnelleSelected" placeholder="Choisissez une unité"
          name="uniteFoncId" id="uniteFoncId" [multiple]="false" [closeOnSelect]="true" (change)="getMedecin()">
          <ng-option *ngFor="let item of listeUniteFonctionnelle" [value]="item.id">{{ item.libelle | uppercase }}
          </ng-option>
        </ng-select>
      </div>

      <div class="col-md-12 col-sm-12 mb-2" *ngIf="!isEnvoiALaCaisseOdonto">
        <label>Médecin :
          <span class="required_champs">*</span>
        </label>

        <ng-select [(ngModel)]="itemToSave.medecinSelectedId" placeholder="Choisissez un médecin" name="medecinSelected"
          id="medecinSelected" [multiple]="false" [closeOnSelect]="true">
          <ng-option *ngFor="let item of listMedecins" [value]="item.id">{{
            item.nom + " " + item.prenom | uppercase
            }}</ng-option>
        </ng-select>
      </div>

      <div class="col-md-12 col-sm-12 mb-3">
        <div class="row">
          <div class="col-12">
            <table class="table table-bordered table-condensed table-hover table-details">
              <thead>
                <tr class="custom-table-head">
                  <th>#</th>
                  <th>Acte</th>
                  <th>Quantité</th>
                  <th>P.C</th>
                  <th>Prix Unitaire (Fcfa)</th>
                  <th>Total (Fcfa)</th>
                  <!-- <th></th> -->
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let type of listActesToSave; let indice = index">
                  <td>{{ indice + 1 }}</td>
                  <td>
                    {{ type?.adminActeLibelle || type?.libelle }}
                  </td>
                  <td>
                    {{ type.quantite }}
                  </td>
                  <td>{{ type?.isPc ? 'Oui' : 'Non' }}</td>
                  <td>{{ type?.prixUnitaire | moneyFormat }}</td>
                  <td>
                    {{ type?.prixUnitaire * type?.quantite | moneyFormat }}
                  </td>
                  <!-- <td class="txt-align-center">
                              <a title="Supprimer " (click)="deleteItemActe(indice)" style="color: red">
                                <i class="fa fa-trash"></i>
                              </a>
                            </td> -->
                </tr>

                <tr *ngIf="itemMontantVente?.total && itemMontantVente?.total > 0">
                  <td colspan="5"> </td>
                  <td class=""
                      style="background-color: orange;font-size:14px;color:#fff;text-align: right;">
                      <!-- <b> {{montantTotal | moneyFormat}} </b> -->
                      <b> Montant total :  {{ (itemMontantVente?.total  | moneyFormat) || 0}} </b> <br>
                      <b> Part patient :  {{ (itemMontantVente?.partPatient | moneyFormat) || 0}} </b> <br>
                      <b> Part assurance :  {{ (itemMontantVente?.partAssurance | moneyFormat) || 0}} </b>
                  </td>
              </tr>

                <tr *ngIf="!listActesToSave || listActesToSave.length == 0">
                  <td colspan="5" style="text-align: center">
                    Aucune donnée disponible
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 mb-2" *ngIf="!isHistoConsultation">
        <div class="btn-manager">
          <!-- <button type="button" class="btn btn-md btn-cancel pull-right mr-1" (click)="closeFormModal()">
                        Fermer
                      </button> -->

          <button type="button" class="btn btn-md custom_btn_primary pull-right" (click)="confirmSaveItem()">
            Envoyer à la caisse
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>