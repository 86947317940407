import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-form-requete-dialyse',
  templateUrl: './form-requete-dialyse.component.html',
  styleUrls: ['./form-requete-dialyse.component.scss']
})
export class FormRequeteDialyseComponent implements OnInit {

  @Input() currentPatient: any;
  @Input() consultation: any;
  @Output() closeForm = new EventEmitter<any>();
  @Input() isHistoConsultation: any;
  @Input() isNewSearchPatient : boolean = false;
  @Input() beneficiaireId : number;
  @Input() typeAssure : string;
  @Input() oldRequeteDialyse : any = {};
  
  itemOldOrdonance : any = {};
  linkPdfFile : string;
  refreshItem: any;
  itemToSaveMedicament: any = {};
  currentMedicament: any = {}
  itemToSearch : any = {};
  listProgrammes: Array<any> = [];
  listAbordVasculaire : Array<any> = [];
  listJourDialyse : any = [];
  listPeriodeDialyse : any = [];
  listEtablissementProvenance : Array<any> = [];
  listTypeDialyse : Array<any> = [];
  itemToSave: any = {};
  busyGet: Subscription;
  listPatientEnReqete : Array<any> = [];
  user: any = {};
  loading: boolean;
  dateActuelle = new Date();

  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService,
    public sanitizer: DomSanitizer
  ) {
    this.user = this.authService.currentUserValue;
  }

  closeFormulaire() {
    this.closeForm.emit(true);
  }

  selectedPatient($event) {
    console.log(" $event ",$event);
    this.currentPatient = {...$event};
  }


  generateRequeteDialyse(requete) {

    if (!requete || !requete.id) {
      this.utilities.showNotification(
        "snackbar-danger", "Veuillez enregistrer une requête !",
        "bottom",
        "center"
      );
    }

    var request = {
      user: this.user.id,
      data: {
        "id": requete.id
      }
    }

    this.busyGet = this.restClient.post('dialyseRequete/generateDialyseRequete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError'] && res['filePath']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePath'] ? res['filePath'] : null;

            this.itemToSave.fileUrlFinale = this.sanitizer.bypassSecurityTrustResourceUrl(this.linkPdfFile);

            this.previewPrint(true);
         
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          // this.notificationService.defaultMessage()
        }
      );
  }


  previewPrint(isOpen) {
    //Recuperé la div details
    let divDetails = document.getElementById("print-part-bilan");

    if (divDetails && isOpen) {
      divDetails.classList.add("open-details");
    } else {
      // Nous fermons notre fenetre de details
      divDetails.classList.remove("open-details");
    }
  }


  getDataRequete() {
    let request = {
      user: this.user.id,
      data: {
        consultationId: this.consultation?.id || null,
        id : this.oldRequeteDialyse?.id || null
      }
    }

    this.busyGet = this.restClient.post('dialyseRequete/getByCriteria', request)
      .subscribe(
        res => {
          
          if (res && res['items']) {
            this.itemToSave = (res['items']?.length > 0) ? res['items'][0] : {};

            // Formater la date
            if(this.itemToSave?.dateFirstEer) {
              let splitDateBySlash= this.itemToSave?.dateFirstEer.split(" ")[0].split("/");
              this.itemToSave.dateFirstEer = new Date(splitDateBySlash[2], splitDateBySlash[1] - 1, splitDateBySlash[0]);
            }

            if(!this.isNewSearchPatient) {
              this.getUpdateInfo();
              this.getData();
            }
          
          }
          else {
            if(!this.isNewSearchPatient) {
             this.getData();
            }
          }
        },
        err => {
        }
      );
  }
  
  getDataEtablissementProvenance() {
    let request = {
      user: this.user.id,
      data: {
        // consultationId: this.consultation?.id
      }
    }

    this.busyGet = this.restClient.post('ucEtablissementEvacuation/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listEtablissementProvenance = _.orderBy(res['items'], ['raisonSociale'], ['asc']);
          this.getUpdateInfo()
          }
          else {
          }
        },
        err => {
        }
      );
  }
  


  getDataAbordVasculaire() {
    let request = {
      user: this.user.id,
      data: {
        // consultationId: this.consultation?.id
      }
    }

    this.busyGet = this.restClient.post('dialyseAbordVasculaire/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listAbordVasculaire = _.orderBy(res['items'], ['libelle'], ['asc']);

            this.getUpdateInfo()
          }
          else {
          }
        },
        err => {
        }
      );
  }

  getJourdialyse() {
    let request = {
      user: this.user.id,
      data: {
        // consultationId: this.consultation?.id
      }
    }

    this.busyGet = this.restClient.post('dialyseJour/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listJourDialyse = _.orderBy(res['items'], ['libelle'], ['asc']);
            this.getUpdateInfo()
          }
          else {
          }
        },
        err => {
        }
      );
  }


  getPeriodeDialyse() {
    let request = {
      user: this.user.id,
      data: {
        // consultationId: this.consultation?.id
      }
    }

    this.busyGet = this.restClient.post('dialysePeriode/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listPeriodeDialyse = _.orderBy(res['items'], ['libelle'], ['asc']);

            this.getUpdateInfo()
          }
          else {
          }
        },
        err => {
        }
      );
  }
  

  getDataTypeDialyse() {
    let request = {
      user: this.user.id,
      data: {
        // consultationId: this.consultation?.id
      }
    }

    this.busyGet = this.restClient.post('dialyseType/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listTypeDialyse = _.orderBy(res['items'], ['libelle'], ['asc']);

            this.getUpdateInfo()
          }
          else {
          }
        },
        err => {
        }
      );
  }


  getDataPatientEnDialyse() {
    let request = {
      user: this.user.id,
      data: {
        // consultationId: this.consultation?.id
      }
    }

    this.busyGet = this.restClient.post('beneficiaire/getBeneficiairesDialyseRequete', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listPatientEnReqete = res['items'];
          }
          else {
          }
        },
        err => {
        }
      );
  }


  getDataPrograme() {

    let request = {
      user: this.user.id,
      data: {}
    }

    this.busyGet = this.restClient.post('dialyseProgramme/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listProgrammes = _.orderBy(res['items'], ['libelle'], ['asc']);

            console.log(" this.listProgramme ",this.listProgrammes);

          this.getUpdateInfo()

          }
          else {
            // this.itemToSave = {};
          }
        },
        err => {
        }
      );
  }
 
  selectedPatientEnDialyse(item) {
    console.log(" item ",item);
    
    if(item) {
      this.itemToSearch.typeAssure = item?.adminCategorieClientLibelle;
      this.beneficiaireId = item?.id;
    }
  }

  getUpdateInfo() {

    if(this.itemToSave?.programmeDialyseId){
      this.itemToSave.programmeSelected = _.find(this.listProgrammes, (o)=> { return o.id == this.itemToSave?.programmeDialyseId });
    }

    if(this.itemToSave?.typeDialyseId){
      this.itemToSave.typeDialyseSelected = _.find(this.listTypeDialyse, (o)=> { return o.id == this.itemToSave?.typeDialyseId });
    }

    if(this.itemToSave?.dialysePeriodeId){
      this.itemToSave.periodeDialyseSelected = _.find(this.listPeriodeDialyse, (o)=> { return o.id == this.itemToSave?.dialysePeriodeId });
    }


    if(this.itemToSave?.dialyseJourId){
      this.itemToSave.jourDialyseSelected = _.find(this.listJourDialyse, (o)=> { return o.id == this.itemToSave?.dialyseJourId });
    }

    if(this.itemToSave?.abordVasculaireId){
      this.itemToSave.abordVasculaireSelected = _.find(this.listAbordVasculaire, (o)=> { return o.id == this.itemToSave?.abordVasculaireId });
    }

    if(this.itemToSave?.etablissementEvacuationId){
      this.itemToSave.etablissementProvenanceSelected = _.find(this.listEtablissementProvenance, (o)=> { return o.id == this.itemToSave?.etablissementEvacuationId });
    }

  }


  confirmSaveItem(item) {

    let objToSave = Object.assign({}, item);

    if (!this.currentPatient?.id) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un patient !",
        "bottom",
        "center");
      return;
    }

    if(!objToSave?.typeDialyseSelected) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le type de dialyse !",
      "bottom",
      "center");
    return;
    }

    // if(!objToSave?.abordVasculaireSelected) {
    //   this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner l'abord vasculaire !",
    //   "bottom",
    //   "center");
    // return;
    // }

    if(!objToSave?.etablissementProvenanceSelected) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un établissement !",
      "bottom",
      "center");
    return;
    }

    if(!objToSave?.jourDialyseSelected) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le jour de dialyse !",
      "bottom",
      "center");
    return;
    }

    if(!objToSave?.periodeDialyseSelected) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner la période de dialyse !",
      "bottom",
      "center");
    return;
    }

    if(!objToSave?.contact) {
      this.utilities.showNotification("snackbar-danger", "Veuillez renseigner le contact !",
      "bottom",
      "center");
    return;
    }


    if(this.itemToSave.dateFirstEer){
      objToSave.dateFirstEer = moment(this.itemToSave.dateFirstEer).format("DD/MM/YYYY")
    }

    objToSave.medecinId = this.user?.id;
    objToSave.consultationId = this.consultation?.id;
    objToSave.abordVasculaireId =  1; //objToSave?.abordVasculaireSelected?.id;
    objToSave.typeDialyseId = objToSave.typeDialyseSelected?.id;
    objToSave.isUrgence = objToSave?.isUrgence || false;
    objToSave.isDiabete = objToSave?.isDiabete || false;
    // objToSave.abordVasculaireId = objToSave?.abordVasculaireSelected?.id;
    objToSave.dialyseJourId = objToSave?.jourDialyseSelected?.id;
    objToSave.dialysePeriodeId = objToSave?.periodeDialyseSelected?.id;
    objToSave.etablissementEvacuationId = objToSave?.etablissementProvenanceSelected?.id;
    objToSave.dateFirstEer = moment().format("DD/MM/YYYY")+" 00:00:00";
    objToSave.beneficiaireId = this.currentPatient?.patientId || this.currentPatient?.id

    Swal.fire({
      title: "Requête dialyse",
      text: objToSave?.id ? "Vous êtes sur le point de modifier une requête. Voulez-vous poursuivre cette action ?" : "Vous êtes sur le point d'enregistrer une requête. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.saveItem(objToSave);
      }
    });
  }

  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ]
    }

    this.busyGet = this.restClient.post('dialyseRequete/'+(itemAEnregistrer?.id ? 'update':'create'), request)
      .subscribe(
        res => {
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            
              this.oldRequeteDialyse = res['items'][0];
              //this.closeFormulaire();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  getData(){
    if(!this.oldRequeteDialyse && !this.oldRequeteDialyse?.id) {
      this.getDataPrograme();
    }
  
    this.getDataEtablissementProvenance();
    this.getDataAbordVasculaire();
    // this.getDataEvacuation();
    this.getDataTypeDialyse();  
    this.getJourdialyse();
    this.getPeriodeDialyse();
  }

  ngOnInit(): void {
    if(!this.isNewSearchPatient) {
      this.getDataRequete();
    }else{
      this.getData();
      this.getDataPatientEnDialyse();
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['currentPatient'] && changes['currentPatient'].currentValue) {
      this.currentPatient = changes['currentPatient'].currentValue;
      this.currentPatient.medecin = this.currentPatient.userNom + " " + this.currentPatient.userPrenom;
    }

    if (changes['typeAssure'] && changes['typeAssure'].currentValue) {
      this.itemToSearch.typeAssure = changes['typeAssure'].currentValue;

      //On rappele les fonctions car nous sommes en modification
      this.getDataRequete();
      this.getData();
    }

    
  }

}
