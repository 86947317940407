import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'textarea[autoResize]'
})
export class AutoResizeDirective {
  constructor(private element: ElementRef) {}

  @HostListener('input') onInput(): void {
    this.resize();
  }

  resize(): void {
    const textarea = this.element.nativeElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  ngAfterContentChecked(): void {
    this.resize();
  }
}
