<ng-container>
  <div [ngBusy]="{busy: busyGet,message: 'Veuillez patienter ...', backdrop: false}">
    <!-- <div class="col-xs-12 col-sm-12 col-md-12"> -->
    <table class="table table-hover table-striped table-condensed">
      <thead>
        <tr *ngIf="title && !isHistoriqueConsultation">
          <th colspan="11" style="text-align: center; background-color: #fff">
            <h2 class="titla">
              {{ title }}
            </h2>
          </th>
        </tr>
        <tr *ngIf="!isHistoriqueConsultation">
          <th colspan="11">
            <div class="col-md-12" *ngIf="!hasNeedPlusColonne">
              <input [(ngModel)]="itemToSearch.searchTxt" class="form-control custom-input pull-left"
                placeholder="Rechercher par matricule du patient" (keyup)="currentPage = 1; getItems()" />
            </div>

            <div class="col-md-3" *ngIf="hasNeedPlusColonne">
              <input [(ngModel)]="itemToSearch.searchTxt" class="form-control custom-input pull-left"
                placeholder="Rechercher par matricule" (keyup)="currentPage = 1; getItems()" />
            </div>
          </th>
        </tr>
        <tr class="custom-table-head">
          <th style="width: 50px" class="txt-align-center">
            <a (click)="currentPage = 1; getItems()" title="Actualiser">
              <i class="fa fa-redo-alt" style="font-size: 10px"></i></a>&nbsp;&nbsp;
          </th>
          <th class="txt-align-center" *ngIf="hasNeedPlusColonne">Photo</th>
          <th>N° Accueil</th>
          <th>Date & Heure</th>
          <th>Matricule</th>
          <th>Patient</th>
          <th>Médecin</th>
          <th>Type acte</th>
          <!-- <th *ngIf="!isCaisseView">Désignation de l'acte</th> -->
          <th *ngIf="hasNeedPlusColonne">Unité fonctionnelle</th>
          <th *ngIf="hasNeedEtapeCircuit" class="txt-align-center">
            Etape circuit
          </th>
          <th *ngIf="hasNumeroDossier" class="txt-align-center" style="width: 160px">
            N° Dossier
          </th>
          <th *ngIf="hasNeedPlusColonne" class="txt-align-center">Action</th>
        </tr>
      </thead>

      <tbody>

        <ng-container *ngIf="isCaisseView">

          <tr *ngFor="let item of items; let indice = index" style="cursor: pointer"
            (click)="(isCaisseView && !canTranfered) ? seletedItem(item) : null"
            [class.rowActive]="item?.id == itemSelected?.id">

            <td class="txt-align-center">
              {{ getExactlyNumberRow(this.currentPage, indice) }}
            </td>
            <td *ngIf="hasNeedPlusColonne" class="txt-align-center">
              <img
                src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                alt="Photo assuré" class="img-assure" />
            </td>
            <td>{{ item?.code }}</td>
            <td>{{ item?.createdAt }}</td>
            <td>{{ item?.matriculeBeneficiaire }}</td>
            <td>
              {{ item?.beneficiaireNom + " " + item?.beneficiairePrenom }}
              <i class="fa-solid fa-hand-back-point-right pull-right"></i>
            </td>
            <td>
              {{ item?.userNom + " " + item?.userPrenom }}
              <i class="fa-solid fa-hand-back-point-right pull-right"></i>
            </td>
            <td>{{ item?.adminTypeActeLibelle }}</td>
            <!-- <td *ngIf="!isCaisseView">

              <span *ngFor="let it of item?.actes">{{ it?.libelle }}</span>
            </td> -->
            <td *ngIf="hasNeedPlusColonne">
              {{ item?.adminUniteFonctionnelleLibelle }}
            </td>

            <td *ngIf="hasNeedEtapeCircuit" class="txt-align-center">
              <span *ngIf="item?.etapeCircuit != 'CONSULTATION EN COURS'" class="badge badge-success not-start"
                [class.termine]="item?.etapeCircuit == 'TERMINE'">
                {{ item?.etapeCircuit }}</span>
              <span *ngIf="item?.etapeCircuit == 'CONSULTATION EN COURS'" class="badge badge-danger in-progress">{{
                item?.etapeCircuit }}
              </span>
            </td>
            <td *ngIf="hasNumeroDossier && item?.numeroDossier && !isArchiveView">
              {{ item?.numeroDossier }}
            </td>

            <td *ngIf="hasNumeroDossier && isArchiveView" class="txt-align-center">
              <div class="num-dossier-part">
                <input type="text" class="form-control" [(ngModel)]="item.numeroDossierRenseigne"
                  [disabled]="item?.isGenerate" placeholder="N°Dossier" />
                <span *ngIf="!item?.numeroDossier">ou</span>
                <button type="button" class="btn btn-xs btn-default btn-generer-numero-dossier"
                  title="Cliquez pour génerer un n° de dossier" (click)="genererNumDossier(item)"
                  *ngIf="!item?.numeroDossier">
                  Génerer n°dossier
                </button>
              </div>
            </td>

            <td *ngIf="hasNeedPlusColonne" class="txt-align-center">
              <!-- <button type="button" class="btn btn-xs btn-default custom_btn_primary" title="Cliquez pour sélectionner"
                *ngIf="!hasNumeroDossier">
                Sélectionner
              </button>
              <button type="button" class="btn btn-xs btn-default custom_btn_primary" title="Cliquez pour sélectionner"
                *ngIf="hasNumeroDossier" (click)="confirmTransfert(item)">
                Transférer
              </button> -->

              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-custom-action-dropdown" id="action"
                  ngbDropdownToggle>Actions</button>
                <div ngbDropdownMenu aria-labelledby="action" class="dropdown-validation-plainte">

                  <!-- <button ngbDropdownItem (click)="openModal(item, templateCrud)">
                        &nbsp;
                        Modifier 
                    </button> -->
                  <button ngbDropdownItem title="Sélectionner" (click)="seletedItem(item)"
                    *ngIf="!hasNumeroDossier">&nbsp;Sélectionner</button>

                

                  <button ngbDropdownItem title="Transférer" (click)="openModal(item,templateTransfertConsultation)"
                    *ngIf="!item.isClotured && canTranfered">&nbsp;Transférer</button>

                  <button ngbDropdownItem title="Transférer" (click)="confirmTransfert(item)"
                    *ngIf="hasNumeroDossier">&nbsp;Envoyé en salle de tri</button>

                  <button ngbDropdownItem title="Envoyé au medecin" (click)="confirmEnvoieChezLeMedecin(item)"
                    *ngIf="hasNumeroDossier">&nbsp;Envoyé au medecin</button>

                    <button ngbDropdownItem title="Terminer la consultation" (click)="confirmTermineConsultation(item)"
                    *ngIf="!item.isClotured && !hasNumeroDossier && !notCanTermineConsultation">&nbsp;Terminer la
                    consultation</button>


                </div>
              </div>


            </td>
          </tr>

        </ng-container>

        <ng-container *ngIf="!isCaisseView">

          <tr *ngFor="let item of items; let indice = index" style="cursor: pointer"
            [class.rowActive]="item?.id == itemSelected?.id">

            <td class="txt-align-center">
              {{ getExactlyNumberRow(this.currentPage, indice) }}
            </td>
            <td *ngIf="hasNeedPlusColonne" class="txt-align-center">
              <img
                src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                alt="Photo assuré" class="img-assure" />
            </td>
            <td>{{ item?.code }}</td>
            <td>{{ item?.createdAt }}</td>
            <td>{{ item?.matriculeBeneficiaire }}</td>
            <td>
              {{ item?.beneficiaireNom + " " + item?.beneficiairePrenom }}
              <i class="fa-solid fa-hand-back-point-right pull-right"></i>
            </td>
            <td>
              {{ item?.userNom + " " + item?.userPrenom }}
              <i class="fa-solid fa-hand-back-point-right pull-right"></i>
            </td>
            <td>{{ item?.adminTypeActeLibelle }}</td>

            <td *ngIf="hasNeedPlusColonne">
              {{ item?.adminUniteFonctionnelleLibelle }}
            </td>

            <td *ngIf="hasNeedEtapeCircuit" class="txt-align-center">
              <span *ngIf="item?.etapeCircuit != 'CONSULTATION EN COURS'" class="badge badge-success not-start"
                [class.termine]="item?.etapeCircuit == 'TERMINE'">
                {{ item?.etapeCircuit }}</span>
              <span *ngIf="item?.etapeCircuit == 'CONSULTATION EN COURS'" class="badge badge-danger in-progress">{{
                item?.etapeCircuit }}
              </span>
            </td>
            <!-- <td *ngIf="hasNumeroDossier && item?.numeroDossier">
            {{ item?.numeroDossier }}
          </td> -->

            <td *ngIf="hasNumeroDossier" class="txt-align-center">
              <div class="num-dossier-part">
                <input type="text" class="form-control" [(ngModel)]="item.numeroDossierRenseigne"
                  [disabled]="item?.isGenerate" placeholder="N°Dossier" />
                <span>ou</span>
                <button type="button" class="btn btn-xs btn-default btn-generer-numero-dossier"
                  title="Cliquez pour génerer un n° de dossier" (click)="genererNumDossier(item)"
                  *ngIf="!item?.numeroDossier">
                  Génerer n°dossier
                </button>
              </div>
            </td>


            <td *ngIf="hasNeedPlusColonne" class="txt-align-center">
              <!-- <button type="button" class="btn btn-xs btn-default custom_btn_primary" title="Cliquez pour sélectionner"
                *ngIf="!hasNumeroDossier">
                Sélectionner
              </button>
              <button type="button" class="btn btn-xs btn-default custom_btn_primary" title="Cliquez pour sélectionner"
                *ngIf="hasNumeroDossier" (click)="confirmTransfert(item)">
                Transférer
              </button> -->

              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-custom-action-dropdown" id="action"
                  ngbDropdownToggle>Actions</button>
                <div ngbDropdownMenu aria-labelledby="action" class="dropdown-validation-plainte">

                  <!-- <button ngbDropdownItem (click)="openModal(item, templateCrud)">
                        &nbsp;
                        Modifier 
                    </button> -->
                  <button ngbDropdownItem title="Sélectionner" (click)="seletedItem(item)"
                    *ngIf="!hasNumeroDossier">&nbsp;Sélectionner</button>
                  <!-- <button ngbDropdownItem title="Terminer la consultation" (click)="confirmTermineConsultation(item)"
                  *ngIf="!item.isClotured && !hasNumeroDossier && !notCanTermineConsultation">&nbsp;Terminer la
                  consultation</button> -->
                  <button ngbDropdownItem title="Transférer" (click)="confirmTransfert(item)"
                    *ngIf="hasNumeroDossier">&nbsp;Transférer</button>


                </div>
              </div>


            </td>
          </tr>

        </ng-container>

      </tbody>

      <tfoot>
        <tr *ngIf="items && items.length > 0" style="border: 1px solid #fff">
          <td colspan="12">
            <div class="row pagination-part">
              <div class="col-md-7" style="text-align: center">
                <pagination [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                  [(ngModel)]="currentPage" [maxSize]="5" class="pagination-sm" previousText="&lsaquo;"
                  nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" (pageChanged)="pageChanged($event)">
                </pagination>
              </div>

              <div class="col-md-5">
                <div class="paginator-per-page">
                  <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                    [defaultItemPerPage]="itemsPerPage">
                  </app-element-by-page-paginator>

                  <span class="span_total_entree pull-right">Total : {{ totalItems }}</span>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr *ngIf="!items || items.length == 0" style="border: 1px solid #fff">
          <td colspan="11">
            <div class="col-md-12 txt-align-center">
              Aucune données disponible
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
    <!-- </div> -->
  </div>
</ng-container>


<!-- Enregistrer une templateTransfertConsultation -->
<ng-template #templateTransfertConsultation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Transfére de consultation
    </h4>
    <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
    <form class="m-1">
      <div class="row">

        <div class="col-md-12 col-sm-12 mb-2">
          <label>Unité fonctionnelle :
            <span class="required_champs">*</span>
          </label>
          <ng-select [(ngModel)]="itemToSave.uniteFonctionnelleSelected" placeholder="Choisissez une unité"
            name="uniteFoncId" id="uniteFoncId" [multiple]="false" [closeOnSelect]="true" (change)="getMedecin()">
            <ng-option *ngFor="let item of listeUniteFonctionnelle" [value]="item.id">{{ item.libelle | uppercase }}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-md-12 col-sm-12 mb-2">
          <label>Médecin :
            <span class="required_champs">*</span>
          </label>

          <ng-select [(ngModel)]="itemToSave.medecinSelectedId" placeholder="Choisissez un médecin"
            name="medecinSelected" id="medecinSelected" [multiple]="false" [closeOnSelect]="true">
            <ng-option *ngFor="let item of listMedecins" [value]="item.id">{{
              item.nom + " " + item.prenom | uppercase
              }}</ng-option>
          </ng-select>
        </div>

        <div class="col-md-12 col-sm-12 mb-2">
          <div class="btn-manager">
            <button type="button" class="btn btn-md btn-cancel pull-right mr-1" (click)="modalRef.hide()">
              Fermer
            </button>&nbsp;&nbsp;

            <button type="button" class="btn btn-md custom_btn_primary pull-right" (click)="confirmSaveItem()">
              Enregistrer
            </button>
          </div>
        </div>


        <!-- <app-form-prescription-medecin-conseil (closeModal)="modalRef.hide();getItems()" [currentPrescription]="itemToSave"></app-form-prescription-medecin-conseil> -->
      </div>
    </form>
  </div>
</ng-template>